import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoginResponse} from "./api/auth.api";
import {RootState} from "./index";

export interface AuthState {
    refreshToken: string
    authToken: string
    user: Record<string, any> | null
    isLoggedIn: boolean
}

const initialState: AuthState = {
    authToken: localStorage.getItem('authToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    isLoggedIn: Boolean(localStorage.getItem('authToken')),
    user: null,
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutUser: (state) => {
            state.isLoggedIn = false;
            state.refreshToken = initialState.refreshToken;
            state.authToken = initialState.authToken;
            state.user = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('resumeUploaded');
            localStorage.removeItem('status');
            localStorage.removeItem('sessionKey');
        },
        loginUser: (state, action: PayloadAction<LoginResponse>) => {
            state.isLoggedIn = true;
            state.authToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            localStorage.setItem('authToken', action.payload.access);
            localStorage.setItem('refreshToken', action.payload.refresh);
        }
    },
})

export default authSlice.reducer;

export const {loginUser, logoutUser} = authSlice.actions;

export const authSelectors = {
    getIsLoggedIn: (state: RootState) => state.auth.isLoggedIn,
}
