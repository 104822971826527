import React, { FC } from 'react';
import ResumePage from './ResumePage'
import InterviewHeader from './InterviewHeader';

interface InterviewPageProps {
    isUserValidForInterview: boolean;
    setIsResumeAlreadyUploaded: (newState: string) => void;
}

const InterviewPage: FC<InterviewPageProps> = ({isUserValidForInterview, setIsResumeAlreadyUploaded}) => {

    return (
        <div className='xl:pt-0 pt-16 w-100'>
            <InterviewHeader/>
            <ResumePage isUserValidForInterview={isUserValidForInterview} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded}/>
        </div>
    )
}

export default InterviewPage;
