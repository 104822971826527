import { ConfigProvider } from "antd";
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import Layouts from "./components/common/Layouts";
import HomePage from "./pages/HomePage";
import CoursePage from "./pages/CoursePage";
import TopicPage from "./pages/TopicPage";
import LoginPage from "./pages/Auth/LoginPage";
import SignUpPage from "./pages/Auth/SignUpPage";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import { useAppSelector } from "./store/index";
import { authSelectors } from "./store/auth.slice";
import ProfilePage from "./pages/ProfilePage";
import InterviewPage from "./pages/Interview/InterviewPage";
import InterviewDetails from "./pages/Interview/InterviewDetails";
import InterviewChat from "./pages/Interview/InterviewChat";
import axios from "axios";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import KidCoursePage from "./pages/KidCoursePage";
import KidsTopicPage from "./pages/KidTopicPage";
import Dashboard from "./pages/Admin/Dashboard";
import UserDetailPageForAdmin from "./pages/Admin/UserDetailPageForAdmin";

const
  theme = {
    token: {
      // colorPrimary: "#222",
      // fontFamily: "Roboto",
      // fontWeightStrong: 400,
      // fontSize: 16,
    },
  };
const App: FC = () => {

  const [isUserValidForInterview, setIsUserValidForInterview] = useState(true)
  const isLoggedIn = useAppSelector(authSelectors.getIsLoggedIn);
  const [isResumeAlreadyUploaded, setIsResumeAlreadyUploaded] = useState('false')
  const [isUserAdmin, setIsUserAdmin] = useState(localStorage.getItem('isUserAdmin') || 'false')
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
  };

  const checkResumeUploaded = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
      .then((response) => {
        const data = response.data.data;
        if (data) {
          setIsResumeAlreadyUploaded('true');
        }
      })
      .catch((error) => {
        localStorage.setItem('status', '');
        console.log(error);
      });
  }

  const checkAdminUser = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/admin/check-admin`, config)
      .then((response) => {
        const data = response.data.data;
        if (data) {
          setIsUserAdmin('true');
          localStorage.setItem('isUserAdmin', 'true');
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/interview/interview-updater/`, {}, config)
        .then((response) => {
          const data = response.data.data;
          setIsUserValidForInterview(data)
        })
        .catch((error) => {
          console.log(error)
        })

      checkResumeUploaded();
      checkAdminUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layouts checkResumeUploaded={checkResumeUploaded} checkAdminUser={checkAdminUser} isUserAdmin={isUserAdmin}/>,
      children: [
        {
          path: "/",
          element: isLoggedIn && isResumeAlreadyUploaded === 'false' ? <InterviewPage isUserValidForInterview={isUserValidForInterview} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded} /> : <Navigate to='/interview-detail' replace />,
        },
        {
          path: 'courses',
          element: isLoggedIn ? <Outlet /> : <Navigate to='/auth/login' replace />,
          children: [
            {
              path: '',
              element: <HomePage checkResumeUploaded={checkResumeUploaded} />,
            },
            {
              path: ':courseId',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <CoursePage />,
                },
                {
                  path: 'topic',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <Navigate to='/courses' replace />,
                    },
                    {
                      path: ':topicId',
                      element: <TopicPage />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'profile',
          element: isLoggedIn ? <ProfilePage checkResumeUploaded={checkResumeUploaded} /> : <Navigate to='/auth/login' replace />,
        },
        {
          path: 'interview',
          element: isLoggedIn && isResumeAlreadyUploaded === 'false' ? <InterviewPage isUserValidForInterview={isUserValidForInterview} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded} /> : <Navigate to='/interview-detail' replace />,
        },
        {
          path: 'interview-detail',
          element: isLoggedIn ? <InterviewDetails setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded} /> : <Navigate to='/auth/login' replace />
        },
        {
          path: 'interview-chat',
          element: isUserValidForInterview && isLoggedIn ? <InterviewChat /> : <Navigate to='/auth/login' replace />,
        },
        {
          path: 'admin',
          element: isUserAdmin === 'true' ? <Outlet/> : <Navigate to='/' replace/>,
          children: [
            {
              path: 'dashboard',
              element: <Dashboard/>,
            },
            {
              path: 'user-detail',
              element: <Outlet/>,
              children: [
                {
                  path: '',
                  element: <Navigate to='/dashboard' replace/>,
                },
                {
                  path: ':userId',
                  element: <UserDetailPageForAdmin/>
                }
              ]
            }
          ]
        },
      ],
    },
    {
      path: 'auth/login',
      element: <LoginPage />
    },
    {
      path: 'auth/sign-up',
      element: <SignUpPage />
    },
    {
      path: 'auth/forgot-password',
      element: <ForgotPasswordPage />
    },
    {
      path: 'auth/reset-password',
      element: <ResetPasswordPage />
    },
    {
      path: 'auth/email-verification',
      element: <VerifyEmailPage />,
    },
    {
      path: 'kids/courses',
      element: <Outlet />,
      children: [
        {
          path: ':courseId',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <KidCoursePage />,
            },
            {
              path: 'topic',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to='/kids/courses/1' replace />,
                },
                {
                  path: ':topicId',
                  element: <KidsTopicPage />
                }
              ]
            }
          ]
        }
      ]
    },
  ]);

  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
