import React, { FC } from "react";
import { Typography } from "antd";

interface historyDataType {
	timestamp: string,
	session_id: string,
}

interface HistoryProfileCardProps {
  currentHistory: historyDataType[];
  nextInterview: string;
}

const HistoryCard: FC<HistoryProfileCardProps> = ({ currentHistory, nextInterview }) => {
    return (
      	<>
			<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
				<div className=" flex flex-row justify-between items-center">
					<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
						Interview History
					</Typography.Title>
					<div>
						<Typography.Text className="font-poppins text-secondry-300 text-sm font-normal leading-6 tracking-[0.14px]">
							Interview completed successfully.
						</Typography.Text>
					</div>
				</div>
				<div className="bg-secondry-100 rounded-lg overflow-auto custom-scrollbar xl:h-44 md:h-36 h-28 p-2 mt-4">
					<ul className="flex flex-col gap-2 text-secondry-300 m-0 pl-7">
						{currentHistory.map((data, index) => {
							return (<li key={index}><Typography.Text className="font-poppins text-secondry-300 text-sm font-normal leading-normal xl:tracking-[1px]">
								{data.timestamp}
							</Typography.Text></li>);
						})}
					</ul>
				</div>
			</div>
      	</>
    );
};

export default HistoryCard;
