import {api} from "./index";
import {SnakeCaseKeys, UserScoreInterface} from "../types";
import {camelToSnakeCase, snakeToCamelCase} from "../../utils/caseConvertor";

interface UpdateProfileDetailsPayload {
    dob: string
    firstName: string
    lastName: string
}

export const profileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateProfileDetails: builder.mutation<{ message: string }, UpdateProfileDetailsPayload>({
            query: (payload) => ({
                url: 'api/profile/',
                method: 'PUT',
                body: camelToSnakeCase([payload])[0],
            })
        }),
        getScoreDetails: builder.query<UserScoreInterface[], void>({
            query: () => ({
                url: `api/topic-courses/scores/`,
            }),
            transformResponse: (
                response: { data: SnakeCaseKeys<UserScoreInterface>[] }
            ) => snakeToCamelCase(response.data.map(i => ({...i, topics: snakeToCamelCase(i.topics)})))
        })
    })
});

export const {
    useUpdateProfileDetailsMutation,
    useGetScoreDetailsQuery,
} = profileApi;