import React, { FC, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row, Spin, Typography } from "antd";
import KidChatBox from "../components/KidChatBox";
import KidCodeEditor from "../components/KidCodeEditor";
import { useGetKidsCoursesQuery, useGetKidsTopicsQuery } from "../store/api/course.api";
import { CourseInterface, TopicInterface } from "../store/types";
import { useCompileCodeMutation, useUpdateKidsTopicStatusMutation } from "../store/api/topic.api";
import ReactMarkdown from "react-markdown";
import { editor } from "monaco-editor";
import capitalize from "antd/es/_util/capitalize";
import AiButton from "../components/common/AIButton";
import { useMediaQuery } from 'react-responsive';

interface TopicPageProps {
}

const KidTopicPage: FC<TopicPageProps> = () => {

    const { courseId, topicId } = useParams<{ courseId: string, topicId: string }>();

    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const kids_id = localStorage.getItem('kids_user_id');

    const {
        currentCourse,
        isLoading: getCoursesLoading,
    } = useGetKidsCoursesQuery(
        { kidsId: String(kids_id) },
        {
            selectFromResult: ({ data, ...rest }) => ({
                currentCourse: data?.find(c => c.courseId === Number(courseId)) || {} as CourseInterface,
                ...rest,
            })
        }
    );

    const { currentTopic, refetch } = useGetKidsTopicsQuery(
        { kidsId: String(kids_id) },
        {
            selectFromResult: ({ data }) => ({
                currentTopic: data?.find(t => t.topicId === Number(topicId)) || {} as TopicInterface
            })
        }
    );

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [
        _,
        {
            isLoading: runCodeLoading,
            isSuccess: runCodeSuccess,
            data: codeOutput,
            isUninitialized,
        }
    ] = useCompileCodeMutation({
        fixedCacheKey: 'run-code-key'
    });

    const [updateTopicStatus] = useUpdateKidsTopicStatusMutation();
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });

    useEffect(() => {
        if (currentTopic?.status === 'Not Started') {
            updateTopicStatus({
                courseId: Number(courseId),
                topicId: Number(topicId),
                status: 'Started',
                kidsId: String(kids_id),
            });
            refetch();
        }
    }, [currentTopic, courseId, topicId, kids_id, updateTopicStatus, refetch]);

    if (getCoursesLoading) {
        return (
            <Row justify='center' className='text-center'>
                <Col span={24}>
                    <Spin />
                </Col>
            </Row>
        )
    }

    if (!getCoursesLoading && currentCourse && !(currentCourse.enabled)) {
        return (
            <Row justify='center' className='text-center'>
                <Col span={24}>
                    <Typography.Title level={1} className='text-center'>
                        {capitalize(currentCourse.courseName || '')} Full Course
                    </Typography.Title>
                </Col>
                <Col span={24}>
                    <Typography.Title level={4}>
                        Opps!, Looks like the current course is disabled.
                    </Typography.Title>
                    <AiButton onClick={() => navigate('/courses')}>
                        Go To Dashboard
                    </AiButton>
                </Col>
            </Row>
        )
    }

    return (
        <Row justify='center' className='xl:pt-0 pt-16'>
            <Col span={24}>
                <Typography.Title level={3} className='text-center capitalize'>
                    Current Topic - {currentTopic?.topicName || ''}
                </Typography.Title>
            </Col>
            <Col span={22} className='overflow-hidden' style={{ height: 'calc(100vh - 224px)' }}>
                <Row className='h-full' gutter={[16, 0]}>
                    <Col span={isTabletOrMobile ? 24 : 10} className='h-full'>
                        <KidChatBox editorRef={editorRef.current} />
                    </Col>
                    {
                        isTabletOrMobile ? (
                            <></>
                        ) : (
                            <Col span={14}>
                                <div className='flex flex-col h-full gap-4'>
                                    <KidCodeEditor
                                        updateEditorRef={(editor) => editorRef.current = editor}
                                    />
                                    <Card
                                        title='Output'
                                        className='h-[200px] flex flex-col code-output'
                                        bodyStyle={{flex: 1, overflow: 'auto'}}
                                    >
                                        {isUninitialized ? (
                                            <Typography>Please write code above and hit run</Typography>
                                        ) : (
                                            <>
                                                {runCodeLoading ? (
                                                    <><Spin/></>
                                                ) : (
                                                    <>
                                                        {runCodeSuccess && (
                                                            <ReactMarkdown>{codeOutput?.compiledOutput || 'Empty Output'}</ReactMarkdown>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Card>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Col>
        </Row>
    );
};

export default KidTopicPage;
