import React, {useMemo, useEffect, FC} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "./Header";
import {Layout} from "antd";
import Sider from "antd/es/layout/Sider";
import Sidebar from "./Sidebar";
import { useMediaQuery } from 'react-responsive';

interface LayoutProps {
    checkResumeUploaded: () => void;
    isUserAdmin: string;
    checkAdminUser: () => void;
}

const Layouts: FC<LayoutProps> = ({checkResumeUploaded, isUserAdmin, checkAdminUser}) => {

    const {pathname} = useLocation();

    const isAuthPage = useMemo(() => pathname.includes('auth'), [pathname]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });

    useEffect(() => {
        checkResumeUploaded();
        checkAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
    <>
        {
            (isAuthPage || isTabletOrMobile)  ? (
                <>
                    {!isAuthPage && <Header isUserAdmin={isUserAdmin}/> }
                    <main className="w-100">
                        <div className='flex-1'>
                            <Outlet/>
                        </div>
                    </main>
                </>
            ) : (
                <Layout className='h-100 w-100'>
                    <Sider className='h-screen text-white top-0 bg-primary' width='250px'>
                        <Sidebar isUserAdmin={isUserAdmin}/>
                    </Sider>
                    <Layout>
                        <main className="w-100">
                            <div className='flex-1'>
                                <Outlet/>
                            </div>
                        </main>
                    </Layout>
                </Layout>
            )
        }
    </>
    );
};

export default Layouts;
