import {CamelCaseKeys, SnakeCaseKeys} from "../store/types";

export const snakeToCamelCase = <T extends Record<any, any>>(data: T[]): CamelCaseKeys<T>[] =>
    data.map((obj) =>
        Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key.replace(/_([a-z])/g, (_, group1) => group1.toUpperCase()),
                value,
            ]))
    ) as CamelCaseKeys<T>[];

export const camelToSnakeCase = <T extends Record<string, any>>(data: T[]): SnakeCaseKeys<T>[] =>
    data.map((obj) =>
        Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`),
                value,
            ])
        )
    ) as SnakeCaseKeys<T>[];
