const SUBMIT_CODE_INPUT_FORMAT = "Program:\n ```\r\n{editorCode}\r\n```\n Output:\n ```\r\n{codeOutput}\r\n```\n";

interface CompiledCodeMessageInterface {
  editorCode: string
  codeOutput: string
}

export const getCompiledCodeMessage = ({editorCode, codeOutput}: CompiledCodeMessageInterface) => {
  return SUBMIT_CODE_INPUT_FORMAT.replace('{editorCode}', editorCode).replace('{codeOutput}', codeOutput);
}

export const COMMENTS: Record<string, string> = {
  "plaintext": "// Please write your code here...",
  "abap": "* Please write your code here...",
  "apex": "// Please write your code here...",
  "azcli": "# Please write your code here...",
  "bat": "REM Please write your code here...",
  "bicep": "// Please write your code here...",
  "cameligo": "(* Please write your code here... *)",
  "clojure": ";; Please write your code here...",
  "coffeescript": "# Please write your code here...",
  "c": "// Please write your code here...",
  "cpp": "// Please write your code here...",
  "csharp": "// Please write your code here...",
  "csp": "// Please write your code here...",
  "css": "/* Please write your code here... */",
  "cypher": "// Please write your code here...",
  "dart": "// Please write your code here...",
  "dockerfile": "# Please write your code here...",
  "ecl": "// Please write your code here...",
  "elixir": "# Please write your code here...",
  "flow9": "// Please write your code here...",
  "fsharp": "// Please write your code here...",
  "freemarker2": "<#-- Please write your code here... -->",
  "freemarker2.tag-angle.interpolation-dollar": "<#-- Please write your code here... -->",
  "freemarker2.tag-bracket.interpolation-dollar": "[#-- Please write your code here... --]",
  "freemarker2.tag-angle.interpolation-bracket": "[#-- Please write your code here... --]",
  "freemarker2.tag-bracket.interpolation-bracket": "[#-- Please write your code here... --]",
  "freemarker2.tag-auto.interpolation-dollar": "[#-- Please write your code here... --]",
  "freemarker2.tag-auto.interpolation-bracket": "[#-- Please write your code here... --]",
  "go": "// Please write your code here...",
  "graphql": "# Please write your code here...",
  "handlebars": "{{!-- Please write your code here... --}}",
  "hcl": "// Please write your code here...",
  "html": "<!-- Please write your code here... -->",
  "ini": "; Please write your code here...",
  "java": "// Please write your code here...",
  "javascript": "// Please write your code here...",
  "julia": "# Please write your code here...",
  "kotlin": "// Please write your code here...",
  "less": "// Please write your code here...",
  "lexon": "// Please write your code here...",
  "lua": "-- Please write your code here...",
  "liquid": "{% comment %} Please write your code here... {% endcomment %}",
  "m3": "// Please write your code here...",
  "markdown": "<!-- Please write your code here... -->",
  "mips": "// Please write your code here...",
  "msdax": "// Please write your code here...",
  "mysql": "# Please write your code here...",
  "objective-c": "// Please write your code here...",
  "pascal": "// Please write your code here...",
  "pascaligo": "// Please write your code here...",
  "perl": "# Please write your code here...",
  "pgsql": "# Please write your code here...",
  "php": "// Please write your code here...",
  "pla": "// Please write your code here...",
  "postiats": "// Please write your code here...",
  "powerquery": "// Please write your code here...",
  "powershell": "# Please write your code here...",
  "proto": "// Please write your code here...",
  "pug": "// Please write your code here...",
  "python": "# Please write your code here...",
  "qsharp": "// Please write your code here...",
  "r": "# Please write your code here...",
  "razor": "// Please write your code here...",
  "redis": "// Please write your code here...",
  "redshift": "// Please write your code here...",
  "restructuredtext": ".. Please write your code here...",
  "ruby": "# Please write your code here...",
  "rust": "// Please write your code here...",
  "sb": "// Please write your code here...",
  "scala": "// Please write your code here...",
  "scheme": ";; Please write your code here...",
  "scss": "/* Please write your code here... */",
  "shell": "# Please write your code here...",
  "sol": "// Please write your code here...",
  "aes": "// Please write your code here...",
  "sparql": "# Please write your code here...",
  "sql": "-- Please write your code here...",
  "st": "// Please write your code here...",
  "swift": "// Please write your code here...",
  "systemverilog": "// Please write your code here...",
  "verilog": "// Please write your code here...",
  "tcl": "# Please write your code here...",
  "twig": "{# Please write your code here... #}",
  "typescript": "// Please write your code here...",
  "vb": "' Please write your code here...",
  "xml": "<!-- Please write your code here... -->",
  "yaml": "# Please write your code here...",
  "json": "// Please write your code here..."
}