import React, {FC, useEffect} from 'react';
import {useAppSelector} from "../../store";
import {authSelectors} from "../../store/auth.slice";
import {useNavigate} from "react-router-dom";
import { Typography } from 'antd';
import ForgotPasswordForm from './ForgotPasswordForm';

interface ForgotPasswordPageProps {
}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = () => {
    const navigate = useNavigate();

    const isLoggedIn = useAppSelector(authSelectors.getIsLoggedIn);

    useEffect(() => {
        if (isLoggedIn) navigate('/');
    }, [navigate, isLoggedIn]);

    return (
        <div className='h-screen w-full'>
            <div className='w-full h-full'>
                <div className='flex lg:flex-row flex-col'>
                    <div className='lg:w-[55%] w-full lg:h-full md:h-[470px] h-[450px] flex'>
                        <div className='flex flex-col justify-center items-center lg:h-screen w-full bg-primary'>
                            <img src="/assets/forgot-page.png" className='lg:w-[332px] lg:h-[332px] md:w-[200px] md:h-[200px] w-[178px] h-[178px]' alt="forgot page" />
                            <div className='md:w-[445px] w-[312px]'>
                                <Typography.Title className='font-poppins text-white lg:text-5xl md:text-4xl text-2xl font-semibold leading-10 md:tracking-[0.48px] tracking-[0.22px] lg:mt-10 mt-6'>
                                    Don’t worry
                                </Typography.Title>
                                <Typography.Title level={2} className='font-poppins text-white lg:text-4xl md:text-2xl text-lg font-medium md:leading-10 leading-normal md:tracking-[0.36px] tracking-[0.18px] lg:mt-8 md:mt-6 mt-4 lg:mb-6 mb-3'>
                                    We will help you reset your Password
                                </Typography.Title>
                                <Typography.Text className='font-poppins text-white lg:text-xl md:text-base text-sm font-normal md:leading-7 leading-normal md:tracking-[0.2px] tracking-[0.14px]'>
                                    Just few steps to reset your password a Link will sent to your mail Id.
                                </Typography.Text>
                            </div>
                        </div>
                    </div>
                    <ForgotPasswordForm/>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage;
