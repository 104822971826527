import React, { FC } from "react";
import { Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import resume from "../Interview/images/Resume.svg";
import ResumeDownload from "./ResumeDownload";

interface ResumeProfileCardForAdminProps {
  resumeName: string;
  resumeDown: Blob | null;
}

const ResumeCardForAdmin: FC<ResumeProfileCardForAdminProps> = ({ resumeName, resumeDown }) => {
  return (
    <>
      <div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
        <Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
          Resume
          {resumeDown ? (
            <ResumeDownload resumeDown={resumeDown} />
            ) : (
                <div style={{ float: "right" }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                className="text-[#262626]"
                                style={{ fontSize: 30 }}
                                spin
                            />
                        }
                    />
                </div>
            )}
        </Typography.Title>

        <div className="bg-secondry-100 flex flex-row gap-10 items-center border border-dashed border-primary rounded-lg p-6 mt-4">
          <img src={resume} className="md:w-[75px] md:h-20 w-14 h-16" alt="resume" />
          <div className="flex flex-col justify-center items-center gap-3">
            <Typography.Text className="font-poppins text-secondry-400 md:text-base text-xs font-medium leading-normal">
              {resumeName}
            </Typography.Text>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeCardForAdmin;
