import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface UserData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  dob: string;
}

const initialState: UserData = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  dob: '',
};

export const getUsDetails = createAsyncThunk<UserData, void, { rejectValue: string }>(
  'usDetails/getUsDetails',
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error('Auth token not found');
      }
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/profile/`, config);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return rejectWithValue('Error fetching user details');
    }
  }
);

const usDetailsSlice = createSlice({
  name: 'usDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsDetails.fulfilled, (state, action) => {
      const { dob, email, first_name, last_name, phone_number } = action.payload;
      return {
        ...state,
        dob,
        email,
        first_name,
        last_name,
        phone_number
      };
    });
  },
});

export default usDetailsSlice.reducer;
