import {api} from "./index";
import {LoginPayload, SignUpPayload} from "../types";
import {camelToSnakeCase} from "../../utils/caseConvertor";
import {loginUser} from "../auth.slice";

export interface LoginResponse {
    refresh: string
    access: string
    message: string
}

export interface VerifyEmailPayload {
    email: string
    token: string
}

interface SignUpResponse extends Omit<SignUpPayload, 'password'> {
}

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginPayload>({
            query: (credentials) => ({
                url: 'auth/user/login',
                method: 'POST',
                body: credentials,
            }),
            // transformResponse: (response: { data: LoginResponse }) => response.data,
            onQueryStarted: async (id, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(loginUser({...data}));
                } catch {
                }
            }
        }),
        signUp: builder.mutation<SignUpResponse, SignUpPayload>({
            query: (payload) => {
                const body: Record<string, any> = {...camelToSnakeCase([payload])[0]};
                body.date_of_birth = payload.dateOfBirth?.format('YYYY-MM-DD');

                return {
                    url: 'auth/user/register',
                    method: 'POST',
                    body,
                }
            },
            transformResponse: (response: { data: SignUpResponse }) => response.data,
        }),
        verifyEmail: builder.mutation<LoginResponse, VerifyEmailPayload>({
            query: ({email, token}) => ({
                url: 'auth/user/email/verify',
                method: 'POST',
                body: {
                    email: email,
                    session_token: token,
                }
            }),
        }),
    })
})

export const {
    useLoginMutation,
    useSignUpMutation,
    useVerifyEmailMutation,
} = authApi;
