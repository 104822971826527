import React, {FC, useEffect} from 'react';
import {DatePicker, Form, Input, Row} from "antd";
import {UserProfileInterface} from "../../store/types";
import {useUpdateProfileDetailsMutation} from "../../store/api/profile.api";
import dayjs from "dayjs";
import AiButton from "../../components/common/AIButton";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; 
import { getUsDetails } from '../../store/usDetailsSlice';
import { ThunkDispatch } from 'redux-thunk';

interface UserDetailsProps {
}

interface UserDetailsFormData extends Omit<UserProfileInterface, 'dob'> {
  dob: dayjs.Dayjs | null
}

const UserDetails: FC<UserDetailsProps> = () => {
  const dispatch: ThunkDispatch<RootState, unknown, any> = useDispatch();
  const { dob, email, first_name, last_name, phone_number } = useSelector((state: RootState) => state.usDetails);
  
  const [form] = Form.useForm<UserDetailsFormData>();
  const [
    updateProfileDetails,
    {
      isLoading: updateProfileLoading,
    }
  ] = useUpdateProfileDetailsMutation();

  const onFinish = async (values: UserDetailsFormData) => {
    updateProfileDetails({
      dob: values.dob?.format('YYYY-MM-DD') || dob || '',
      firstName: values.firstName || first_name || '',
      lastName: values.lastName || last_name || '',
    });
  };


  useEffect(() => {
    dispatch(getUsDetails());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      dob: dob ? dayjs(dob, 'YYYY-MM-DD') : null,
      email: email || '',
      phoneNumber: phone_number || '',
      firstName: first_name,
      lastName: last_name
    });
  }, [dob, email, first_name, last_name, phone_number, form]);

  return (
    <Row justify='start'>
       <Row justify="start">
    </Row>
        <Form
          name='user-details'
          className='login-form w-[50%]'
          form={form}
          layout='vertical'
          onFinish={onFinish}
          autoComplete="off"
        >
              <Form.Item
                label="Date Of Birth"
                name="dob"
              >
                <DatePicker allowClear className='w-full'/>
              </Form.Item>
              
              <Form.Item
                label="First name"
                name="firstName"
              >
                <Input className='font-poppins lg:h-12 h-11'/>
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
              >
               <Input className='font-poppins lg:h-12 h-11'/>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
              >
                <Input disabled/>
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
              >
                <Input disabled/>
              </Form.Item>

              <AiButton
                htmlType='submit'
                loading={updateProfileLoading}
              >
                Update
              </AiButton>

        </Form>
    </Row>
  );
};

export default UserDetails;