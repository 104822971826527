import React, {FC, useEffect} from 'react';
import {Spin, Typography} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useVerifyEmailMutation} from "../../store/api/auth.api";

interface VerifyEmailPageProps {
}

const VerifyEmailPage: FC<VerifyEmailPageProps> = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [verifyEmail, {isLoading, isError, isSuccess}] = useVerifyEmailMutation();

    useEffect(() => {
        if (searchParams.has('token')) {
            const token = searchParams.get('token');
            const email = searchParams.get('email');
            verifyEmail({
                email: email || ' ', 
                token: token || '',
            });
        }
    }, [searchParams, verifyEmail]);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                navigate('/auth/login');
            }, 2000);
        }

    }, [isSuccess, navigate]);

    return (
        <div className='flex justify-center items-center'>
            {isLoading ? (
                <div className='flex flex-col gap-8'>
                    <Typography.Text className='text-3xl'>
                        Please wait while we verify your email
                    </Typography.Text>
                    <Spin/>
                </div>
            ) : (
                <>
                    {isError && (
                        <Typography.Text className='text-3xl'>
                            Token is not valid or expired. Please try again. Email has been sent again.
                        </Typography.Text>
                    )}
                    {isSuccess && (
                        <Typography.Text className='text-3xl'>
                            User Email verified successfully!. You will be redirect to login page shortly.
                        </Typography.Text>
                    )}
                </>
            )}
        </div>
    );
};

export default VerifyEmailPage;
