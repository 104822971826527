import React, { FC } from 'react'
import {
    Progress,
    Typography
} from 'antd';

interface ResumeFeedbackScoreCardForAdminProps {
    feedbackList: string[];
    actualScore: number;
    totalScore: number;
}

const ResumeFeedbackScoreCardForAdmin: FC<ResumeFeedbackScoreCardForAdminProps> = ({feedbackList, actualScore, totalScore}) => {
    const percentageScore = parseFloat(((actualScore / totalScore) * 100).toFixed(2));
    let color = '';
    if (percentageScore <= 25) {
        color = '#DA0C0C'
    } else if (percentageScore <= 50) {
        color = '#E59700'
    } else if (percentageScore <= 75) {
        color = '#D3E500'
    } else if (percentageScore <= 90) {
        color = '#5AA000'
    } else if (percentageScore <= 100) {
        color = '#72CA00'
    } else {
      color = '#2D2D2D'
    }

    const customFormat = (percent: any) => {
        return <span className='font-poppins text-2xl font-bold' style={{ color: color }}>{`${percent}%`}</span>;
    };

    return (
        <>
            <div className="flex flex-col justify-between lg:flex-row gap-5 lg:gap-20 w-full h-auto mt-3">
                <div className='w-full'>
                    <Typography.Title className='font-poppins text-secondry-400 text-base font-medium leading-normal xl:tracking-[1px] mt-0'>
                        Interview Feedback
                    </Typography.Title>
                    <div className="h-40 overflow-auto custom-scrollbar rounded-lg bg-secondry-100 p-2">
                        <ul className='font-poppins text-secondry-300 text-sm font-normal leading-normal xl:tracking-[1px]'>
                        {
                            feedbackList.map((feedback, index) => (
                                <li key={index}>{ feedback }</li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
                <div className='w-full lg:w-[30%]'>
                    <Typography.Title className='font-poppins text-black text-sm font-medium leading-normal mt-0'>
                        Score
                    </Typography.Title>
                    <div className="flex flex-row items-center gap-5">
                        <Progress type="circle" percent={percentageScore} format={(percentageScore) => customFormat(percentageScore)} strokeColor={color}/>
                        <div className='flex flex-col'>
                            <div className='flex gap-2'>
                                <div className='w-4 h-4 rounded-full bg-custom-green'/>
                                <div className='font-poppins text-black text-sm font-normal leading-normal'>91-100%</div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-4 h-4 rounded-full bg-custom-darkgreen'/>
                                <div className='font-poppins text-black text-sm font-normal leading-normal'>76-90%</div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-4 h-4 rounded-full bg-custom-yellow'/>
                                <div className='font-poppins text-black text-sm font-normal leading-normal'>51-75%</div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-4 h-4 rounded-full bg-custom-brown'/>
                                <div className='font-poppins text-black text-sm font-normal leading-normal'>26-50%</div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-4 h-4 rounded-full bg-custom-red'/>
                                <div className='font-poppins text-black text-sm font-normal leading-normal'>0-25%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeFeedbackScoreCardForAdmin;
