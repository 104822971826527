import React, {FC, useCallback, useEffect, useState} from 'react';
import {Alert, AlertProps, Avatar, Button, Card, Form, Input, Modal, Skeleton, Space, theme, Typography} from "antd";
import {CrownOutlined, MessageTwoTone, UserOutlined} from "@ant-design/icons";
import {useCompileCodeMutation, useGetKidsChatHistoryQuery, useSendKidsChatMutation} from "../store/api/topic.api";
import {useNavigate, useParams} from "react-router-dom";
import {ChatInterface, TopicInterface} from "../store/types";
import ReactMarkdown from "react-markdown";
import ChatSuggestion from "./common/ChatSuggestion";
import {getCompiledCodeMessage} from "../utils/constants";
import {editor} from "monaco-editor";
import {useGetKidsTopicsQuery} from "../store/api/course.api";

interface KidChatBoxProps {
    editorRef: editor.IStandaloneCodeEditor | null
}

interface ChatForm {
    message: string
}

const SenderIconMapping: Record<ChatInterface['messageSender'], { type: AlertProps['type'], icon: any }> = {
    A: {
        type: 'success',
        icon: <Avatar size='small' style={{backgroundColor: theme.getDesignToken().colorSuccessBgHover}}
                      className='flex justify-center align-middle' icon={<CrownOutlined/>}/>
    },
    H: {
        type: 'warning',
        icon: <Avatar size='small' style={{backgroundColor: theme.getDesignToken().colorWarningBgHover}}
                      className='flex justify-center align-middle' icon={<UserOutlined/>}/>
    },
    L: {
        type: 'warning',
        icon: <Skeleton.Avatar size='small'/>
    },
}

const KidChatBox: FC<KidChatBoxProps> = ({editorRef}) => {

    const {courseId, topicId} = useParams<{ courseId: string, topicId: string }>();
    const navigate = useNavigate();

    const [modal, contextHolder] = Modal.useModal();

    const [form] = Form.useForm<ChatForm>();
    const values = Form.useWatch([], form);

    const [chats, setChats] = useState<ChatInterface[]>([])
    const kids_id = localStorage.getItem('kids_user_id');

    const {
        isLoading: getChatHistoryLoading,
        // isSuccess: getChatHistorySuccess,
        data: chatHistory,
        refetch: refetchChatHistory,
    } = useGetKidsChatHistoryQuery({
        courseId: Number(courseId),
        topicId: Number(topicId),
        kidsId: String(kids_id),
    }, {
        refetchOnMountOrArgChange: true,
    });

    const {
        data: topics,
        refetch: refetchTopics
    } = useGetKidsTopicsQuery({ kidsId: String(kids_id) }, {refetchOnMountOrArgChange: true});

    const [
        sendChat,
        {
            isLoading: sendChatLoading,
            // isSuccess: sendChatSuccess,
            // data: sendChatRes
            isSuccess: sendChatSuccess,
        }
    ] = useSendKidsChatMutation();

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [
        _,
        {
            isLoading: runCodeLoading,
            isSuccess: runCodeSuccess,
            data: codeOutput,
            reset: runCodeReset,
        }
    ] = useCompileCodeMutation({
        fixedCacheKey: 'run-code-key',
    });

    const sendSuggestion = (msg: string) => {
        if (sendChatLoading) return;
        setChats(chats => [
            ...chats, {
                messageSender: 'H',
                message: msg,
            }
        ]);
        sendChat({
            courseId: Number(courseId),
            topicId: Number(topicId),
            message: msg,
            kidsId: String(kids_id),
        });
        form.resetFields();
    }

    const onFinish = (values: ChatForm) => {
        sendSuggestion(values.message);
    }

    const onReset = () => {
        form.resetFields();
    }

    const redirectToNextTopic = useCallback((nextTopicToStart: TopicInterface) => {
        let secondsToGo = 5;

        const instance = modal.success({
            title: 'Topic is completed',
            content: (
                <Typography>
                    You will be redirected to next
                    topic <strong>{nextTopicToStart.topicName}</strong> after {secondsToGo} second(s).
                </Typography>
            )
        });

        const timer = setInterval(() => {
            secondsToGo -= 1;
            instance.update({
                content: (
                    <Typography>
                        You will be redirected to next
                        topic <strong>{nextTopicToStart.topicName}</strong> after {secondsToGo} second(s).
                    </Typography>
                )
            });
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
            instance.destroy();
            navigate(`../${nextTopicToStart.topicId}`);
        }, secondsToGo * 1000);
    }, [modal, navigate]);

    useEffect(() => {
        runCodeReset();
    }, [runCodeReset]);

    useEffect(() => {
        if (!chatHistory || chatHistory.data.length === 0) return;
        setChats([...chatHistory.data]);
    }, [chatHistory]);

    useEffect(() => {
        if (topics && topics.length > 0) {
            const allTopicsDone = topics.every(t => t.status === 'Done');
            if (allTopicsDone) {
                // open modal with button to go to home page
                const instance = modal.success({
                    title: 'Well Done!',
                    content: `All the topics of current course are completed`,
                    okText: 'Go To Dashboard',
                    onOk: () => {
                        instance.destroy();
                        navigate('/kids/courses/1');
                    }
                });
                return;
            }
            const currentTopic = topics.find(t => t.topicId === Number(topicId));
            if (currentTopic && currentTopic.status === 'Done') {
                setTimeout(() => {
                    navigate('/kids/courses/1');
                }, 5000);
            }
        }
    }, [chatHistory, topics, navigate, redirectToNextTopic, modal, topicId]);

    useEffect(() => {
        if (!chats || chats.length === 0) return;
        const el = document.getElementById('chat-history-container');
        if (el) {
            el.scrollTo({
                top: el.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [chats]);

    useEffect(() => {
        if (!runCodeLoading && runCodeSuccess && codeOutput && editorRef?.getValue()) {
            form.setFieldValue('message', getCompiledCodeMessage({
                editorCode: editorRef?.getValue() || '',
                codeOutput: codeOutput?.compiledOutput
            }))
        }
    }, [runCodeLoading, runCodeSuccess, codeOutput, editorRef, form]);

    useEffect(() => {
        if (sendChatSuccess) {
            refetchChatHistory();
            refetchTopics();
        }
    }, [sendChatSuccess, refetchChatHistory, refetchTopics]);

    return (
        <>
            {contextHolder}
            <Card
                title='Chat Bot'
                className='flex flex-col h-full'
                bodyStyle={{flex: 1}}
                actions={[
                    <div className='px-6'>
                        <div className='w-full flex justify-start items-center mb-3 gap-2 no-scrollbar'>
                            <MessageTwoTone/>
                            <ChatSuggestion
                                message='Ok'
                                onClick={() => sendSuggestion('Ok')}
                                disabled={chatHistory?.status === 'Done' || getChatHistoryLoading || sendChatLoading}
                            />
                            <ChatSuggestion
                                message='Continue'
                                onClick={() => sendSuggestion('Continue')}
                                disabled={chatHistory?.status === 'Done' || getChatHistoryLoading || sendChatLoading}
                            />
                            <ChatSuggestion
                                message='Why?'
                                onClick={() => sendSuggestion('Why?')}
                                disabled={chatHistory?.status === 'Done' || getChatHistoryLoading || sendChatLoading}
                            />
                            <ChatSuggestion
                                message='Go Ahead'
                                onClick={() => sendSuggestion('Go Ahead')}
                                disabled={chatHistory?.status === 'Done' || getChatHistoryLoading || sendChatLoading}
                            />
                        </div>
                        <Form
                            form={form}
                            disabled={chatHistory?.status === 'Done' || getChatHistoryLoading || sendChatLoading}
                            layout='inline'
                            name='chat-form'
                            onFinish={onFinish}
                            onReset={onReset}
                        >
                            <Form.Item name="message" className='!flex-1'>
                                <Input placeholder='Type something....' allowClear autoComplete='off'/>
                            </Form.Item>
                            <Form.Item className='!m-0'>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={sendChatLoading}
                                    disabled={values && 'message' in values && !Boolean(values.message)}
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                ]}
            >
                <Space
                    size='middle'
                    direction='vertical'
                    className='w-full h-[calc(100vh-426px)] overflow-auto custom-scrollbar'
                    id='chat-history-container'
                >
                    {getChatHistoryLoading ? (
                        <>
                            {Array.from({length: 9}).map((i, idx) => (
                                <Alert
                                    showIcon
                                    key={`message-loading-${idx}`}
                                    icon={SenderIconMapping.L.icon}
                                    className={[(idx % 2) === 0 ? 'ml-auto' : ''].join(' ')}
                                    type={SenderIconMapping[(idx % 2 === 0 ? 'H' : 'A')].type}
                                    style={{width: `max(${Math.floor(Math.random() * (65 - 10 + 1)) + 10}%, 180px)`}}
                                    message={<Skeleton.Input size='small' block/>}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {chats && chats.length > 0 ? (
                                <>
                                    {chats.slice(1).map((message, idx) => (
                                        <Alert
                                            showIcon
                                            key={`message-${idx}`}
                                            icon={SenderIconMapping[message.messageSender].icon}
                                            className={['w-fit max-w-[65%]', message.messageSender === 'H' ? 'ml-auto' : '', 'items-start'].join(' ')}
                                            type={SenderIconMapping[message.messageSender].type}
                                            message={(
                                                <ReactMarkdown>{typeof message.message === 'string' ? message.message : ''}</ReactMarkdown>)}
                                        />
                                    ))}
                                    {sendChatLoading && (
                                        <Alert
                                            showIcon
                                            key={`message-loading-end`}
                                            icon={SenderIconMapping.L.icon}
                                            type={SenderIconMapping.A.type}
                                            style={{width: `max(${Math.floor(Math.random() * (65 - 10 + 1)) + 10}%, 180px)`}}
                                            message={<Skeleton.Input size='small' block/>}
                                        />
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </Space>
            </Card>
        </>
    );
};

export default KidChatBox;
