import React, { FC, useEffect, useRef, useState } from "react";
import {
  EditOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Input, Space, Tag, theme, Tooltip, Typography } from "antd";
import axios from "axios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface ProjectsCardForAdminProps {
  initialProjects: string[];
  haveNoProjects: boolean;
  setNoProjects: (newState: boolean) => void;
  setProjects: (newState: any) => void;
}

const ProjectsCardForAdmin: FC<ProjectsCardForAdminProps> = ({ initialProjects, haveNoProjects, setNoProjects, setProjects }) => {
  const [isEditing, setIsediting] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
  };

  const { token } = theme.useToken();
  const [tags, setTags] = useState(initialProjects);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (initialProjects.length !== 0) {
      setTags(initialProjects);
      setLoading(false);
    }
  }, [initialProjects]);

  useEffect(() => {
    if (haveNoProjects || !haveNoProjects) {
      setLoading(false);
    }
  }, [haveNoProjects]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const updateDatabase = () => {
    setIsediting(false);
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/interview/interview-details/`,
        {
          experience_projects: tags,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        axios
          .get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
          .then((response) => {
            const data = response.data.data;
            const validJsonString = data.projects
              .replace(/'/g, '"')
              .replace(/(\w+)\s*:/g, '"$1":');
            setTags(JSON.parse(validJsonString));
            setProjects(JSON.parse(validJsonString));
            if (JSON.parse(validJsonString).length === 0) {
              setNoProjects(true);
            }
            else{
              setNoProjects(false);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  return (
    <>
      <div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
        <div className="flex flex-row gap-6">
          <Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
            Projects
          </Typography.Title>
          {isEditing ? (
            <>
              <div
                className="cursor-pointer"
                onClick={() => {
                  updateDatabase();
                }}
              >
                <CheckCircleFilled className="text-primary text-2xl" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setTags(initialProjects);
                  setIsediting(false);
                }}
              >
                <CloseCircleFilled className="text-primary text-2xl" />
              </div>
            </>
          ) : (
            <div
              className="cursor-pointer"
              onClick={() => setIsediting(true)}
            >
              <EditOutlined className="text-primary text-2xl"/>
            </div>
          )}
        </div>
        <div className="flex flex-row mt-6">
          {isLoading ? (
            <div className="flex w-[100%] justify-center">
              <Spin
                indicator={
                  <LoadingOutlined
                    className=" text-[#262626]"
                    style={{ fontSize: 30 }}
                    spin
                  />
                }
              />
            </div>
          ) : (
            <>
              {isEditing ? (
                <>
                  <div className="flex flex-1 max-h-36 overflow-auto custom-scrollbar">
                    <Space size={[0, 8]} wrap>
                      {tags.map((tag, index) => {
                        if (editInputIndex === index) {
                          return (
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          );
                        }
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                          <Tag
                            key={tag}
                            closable={true}
                            style={{ userSelect: "none" }}
                            onClose={() => handleClose(tag)}
                            bordered={false}
                            className="rounded-lg bg-secondry-100"
                          >
                            <span
                              onDoubleClick={(e) => {
                                if (index !== 0) {
                                  setEditInputIndex(index);
                                  setEditInputValue(tag);
                                  e.preventDefault();
                                }
                              }}
                            >
                              {tag}
                            </span>
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })}
                      {inputVisible ? (
                        <Input
                          ref={inputRef}
                          type="text"
                          size="small"
                          style={tagInputStyle}
                          value={inputValue}
                          onChange={handleInputChange}
                          onBlur={handleInputConfirm}
                          onPressEnter={handleInputConfirm}
                        />
                      ) : (
                        <Tag
                          style={tagPlusStyle}
                          icon={<PlusOutlined />}
                          onClick={showInput}
                        >
                          Add project
                        </Tag>
                      )}
                    </Space>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-1 max-h-36 overflow-auto custom-scrollbar">
                    {haveNoProjects ? (
                      <>Add some projects</>
                    ) : (
                      <Space size={[0, 8]} wrap>
                        {tags.map((tag, index) => {
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag}
                              closable={false}
                              style={{ userSelect: "none" }}
                              onClose={() => handleClose(tag)}
                              bordered={false}
                              className="rounded-lg bg-secondry-100"
                            >
                              <span
                                onDoubleClick={(e) => {
                                  if (index !== 0) {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}
                      </Space>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectsCardForAdmin;
