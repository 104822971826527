import React, { useState, useEffect } from 'react';
import { FieldTimeOutlined } from '@ant-design/icons';
import '../../styles/interview-chat.css';

interface CountdownTimerProps {
  minutes: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ minutes }) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(minutes * 60);
  const [countingUp, setCountingUp] = useState<boolean>(false);
  const [timeExceeded, setTimeExceeded] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (!countingUp) {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            setCountingUp(true);
            setTimeExceeded(true);
            return 1;
          }
        } else {
          return prevTime + 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countingUp]);

  const hours = Math.floor(timeRemaining / 3600);
  const minutesLeft = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  const timerClass = timeExceeded ? "timer timer-expired" : "timer";
  const titleText = timeExceeded ? "Time exceeded" : "Time remaining";

  return (
    <div className="time-container" style={{ zIndex: window.innerWidth >= 1280 ? 100 : 0 }}> 
      <header className="header">
        <div className={timerClass}>
          <p className={`title ${timeExceeded ? 'title-red' : ''}`}>
            <FieldTimeOutlined />
            {titleText}
          </p>
          <div className={`digit ${countingUp ? 'digit-red' : ''}`}>{("0" + hours).slice(-2)}</div>
          <div className={`digit ${countingUp ? 'digit-red' : ''}`}>{("0" + minutesLeft).slice(-2)}</div>
          <div className={`digit ${countingUp ? 'digit-red' : ''}`}>{("0" + seconds).slice(-2)}</div>
        </div>
      </header>
    </div>
  );
};

export default CountdownTimer;
