import React from 'react';
import { Typography } from 'antd';

const InterviewHeader = () => {
    return (
        <div className='xl:px-12 md:px-9 px-6 xl:py-10 py-3 bg-secondry-100'>
            <Typography.Title className='font-poppins text-secondry-400 md:text-2xl text-base font-semibold leading-normal xl:tracking-[1px] m-0'>
                Interview
            </Typography.Title>
            <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-normal xl:tracking-[1px]'>
                Elevate your Interview skills with AI-Powered Questions
            </Typography.Text>
        </div>
    )
}

export default InterviewHeader;
