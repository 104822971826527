import React from "react";
import {Typography} from "antd";

const Logo = () => {
    return (
        <Typography.Text className='font-poppins text-4xl'>
            AI Tutor
        </Typography.Text>
    )
};

export default Logo;
