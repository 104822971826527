import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { Spin, Select, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface JobProfileCardForAdminProps {
    currentJobProfile: string;
}

const JobProfileCardForAdmin: FC<JobProfileCardForAdminProps> = ({ currentJobProfile }) => {
	const [text, setText] = useState(currentJobProfile);
	const [isLoading, setLoading] = useState(true);

	const config = {
		headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
	};

  	const jobProfiles = [
		{
			value: "Intern Full Stack Web Developer",
			label: "Intern Full Stack Web Developer",
		},
		{
			value: "Intern Machine Learning Engineer",
			label: "Intern Machine Learning Engineer",
		},
		{
			value: "Full Stack Web Developer",
			label: "Full Stack Web Developer",
		},
		{
			value: "Machine Learning Engineer",
			label: "Machine Learning Engineer",
		},
		{
			value: "Senior Full Stack Engineer",
			label: "Senior Full Stack Engineer",
		},
		{
			value: "Senior Data Scientist",
			label: "Senior Data Scientist",
		},
		{
			value: ".NET Developer",
			label: ".NET Developer",
		},
	];

  	const updateDatabase = (text: string) => {
		setText(text);
		setLoading(true);
		axios
		.post(
			`${process.env.REACT_APP_BASE_URL}api/interview/interview-details/`,
			{
			field: text,
			},
			config
		)
		.then((response) => {
			console.log(response.data);
			setLoading(false);
		})
		.catch((error) => {
			console.log(error);
		});
	};

	useEffect(() => {
		if (currentJobProfile) {
		setText(currentJobProfile);
		setLoading(false);
		}
	}, [currentJobProfile]);

  	return (
		<>
			<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
				<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
					Job Profile
				</Typography.Title>
				{isLoading ? (
					<div className="flex w-[100%] justify-center">
						<Spin
							indicator={
								<LoadingOutlined
									className=" text-[#262626]"
									style={{ fontSize: 30 }}
									spin
								/>
							}
						/>
					</div>
				) : (
					<Select
						defaultValue={text}
						className="bg-white border border-solid border-secondry-200 rounded-lg w-full h-12 mt-3"
						onChange={(value) => updateDatabase(value)}
						options={jobProfiles}
					/>
				)}
			</div>
		</>
  	);
}

export default JobProfileCardForAdmin;
