import React, {FC, MouseEventHandler} from 'react';

interface InterviewSuggestionProps {
    message: string
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled: boolean
}

const InterviewSuggestion: FC<InterviewSuggestionProps> = ({
                                                     message,
                                                     onClick,
                                                     disabled = false,
                                                 }) => {
    return (
        <button
            className='font-poppins text-secondry-400 text-base font-normal leading-normal px-5 py-[6px] border border-solid border-secondry-200 rounded bg-white hover:cursor-pointer m-0 hover:text-primary hover:border-primary hover:bg-blue-50'
            onClick={onClick}
            disabled={disabled}
        >
            {message}
        </button>
    );
};

export default InterviewSuggestion;