import React, {FC, useEffect, useState} from 'react';
import {Button, DatePicker, Divider, Form, Input, notification, Typography} from "antd";
import {SignUpPayload} from "../../store/types";
import {useSignUpMutation} from "../../store/api/auth.api";
import PhoneInput, {CountryData} from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface SignUpFormProps {
}

const SignUpForm: FC<SignUpFormProps> = () => {

    const [form] = Form.useForm<SignUpPayload>();
	const [api, contextHolder] = notification.useNotification();

	const [signUp, {isLoading, isSuccess}] = useSignUpMutation();

	const onFinish = async (values: SignUpPayload) => {
		values.phoneNumber = phone;
		await signUp({...values});
	};

	const [phone, setPhone] = useState<string>("");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });


	const countryUtility = (phone: string, country: CountryData | {}) => {
		if (!('dialCode' in country)) return '';

		let formattedPhone: string = "+";
		const dialCode: string = country.dialCode;
		formattedPhone += dialCode + " ";
		const trimmedPhoneNumber = phone.slice(dialCode.length);
		const finalFormattedPhone = formattedPhone + trimmedPhoneNumber;
		setPhone(finalFormattedPhone);
	}

	useEffect(() => {
		if (!isLoading && isSuccess) {
			form.resetFields();
			api.success({
				message: 'User registered successfully!!',
				description: 'Please check your mailbox and verify your email before logging in',
				duration: 0,
			});
		}
	}, [form, isSuccess, isLoading, api])

    return (
        <>
            {contextHolder}
            <div className='lg:w-[45%] w-full flex flex-col justify-center items-center xl:pl-16 lg:py-0 py-6 bg-white'>
                <div className='flex flex-col lg:pl-0 pl-6'>
                    <Typography.Title className='font-poppins text-secondry-400 xl:text-4xl lg:text-2xl md:text-4xl text-2xl font-medium leading-10 tracking-[0.36px] m-0'>
                        Let’s Create your Account
                    </Typography.Title>
                    <div className='xl:w-[480px] lg:w-80 md:w-[480px] w-80'>
                        <Divider className='h-2 bg-primary rounded-[30px] m-0'/>
                    </div>
                    <div className='md:w-[425px] w-[90%] flex flex-col justify-center'>
                        <Typography.Title level={2} className='font-poppins text-secondry-400 md:text-2xl text-lg font-semibold leading-10 tracking-[0.24px] m-0 lg:mt-14 mt-6'>
                            Create Account
                        </Typography.Title>
                        <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal md:leading-10 leading-6 tracking-[0.16px]'>
                            Please Enter your details to start your Account
                        </Typography.Text>
                        <Form
                            size='large'
                            name='signUp'
                            className='signup-form lg:mt-10 mt-6'
                            form={form}
                            layout='vertical'
                            onFinish={onFinish}
                            autoComplete="off"
                        >


                            <Form.Item
                                label="First name"
                                name="firstName"
                                rules={[
                                    {required: true, message: 'Please input your First Name!'},
                                    {type: 'string', message: 'First Name is not valid!'},
                                ]}
                            >
                                <Input className='font-poppins lg:h-12 h-11'/>
                            </Form.Item>

                            <Form.Item
                                label="Last name"
                                name="lastName"
                                rules={[
                                    {type: 'string', message: 'Last Name is not valid!'},
                                ]}
                            >
                                <Input className='font-poppins lg:h-12 h-11'/>
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {required: true, message: 'Please input your email!'},
                                    {type: 'email', message: 'Email is not valid!'},
                                ]}
                            >
                                <Input className='font-poppins lg:h-12 h-11'/>
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {required: true, message: 'Please input your password!'},
                                ]}
                            >
                                <Input.Password className='font-poppins lg:h-12 h-11'/>
                            </Form.Item>

                            <Form.Item
                                label="Date Of Birth"
                                name="dateOfBirth"
                                rules={[
                                    {required: true, message: 'Please input your date of birth!'},
                                ]}
                            >
                                <DatePicker inputReadOnly className='font-poppins w-full lg:h-12 h-11' placeholder='yyyy-mm-dd'/>
                            </Form.Item>

                            <Form.Item
                                label="Phone Number"
                                name="phoneNumber"
                                rules={[
                                    {required: true, message: 'Please input your phone number!'},
                                ]}
                            >
                                <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    value={phone}
                                    placeholder='Enter your phone number'
                                    onChange={(phone, country) => countryUtility(phone, country)}
                                    inputStyle={{'width': '100%', 'height': isTabletOrMobile ? '44px' : "48px", fontFamily: 'poppins'}}
                                />
                            </Form.Item>

                            <Form.Item className='mt-16 mb-0'>
                                <Button
                                    block
                                    type='primary'
                                    htmlType='submit'
                                    loading={isLoading}
                                    className='font-poppins text-white text-base font-semibold leading-5 bg-primary rounded-lg lg:h-12 h-11'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className='flex justify-center lg:mt-10 mt-6'>
                            <Typography.Text className='font-poppins text-secondry-400 text-base font-medium leading-5 tracking-[0.16px]'>
                                Already have an Account?, <Link to={'/auth/login'} className='font-poppins text-primary text-base font-semibold leading-5 tracking-[0.16px]'>Login.</Link>
                            </Typography.Text>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpForm;
