import React, {FC, useMemo} from 'react';
import {Collapse, CollapseProps, Table, theme, Typography} from 'antd';
import {useGetScoreDetailsQuery} from "../../store/api/profile.api";
import {CaretRightOutlined} from "@ant-design/icons";
import capitalize from "antd/es/_util/capitalize";
import {TopicScoreInterface} from "../../store/types";
import {ColumnsType} from "antd/es/table";

interface UserScoreProps {
}

const columns: ColumnsType<TopicScoreInterface> = [
    {
        title: 'No.',
        dataIndex: 'id',
        key: 'id',
        sorter:  (a, b) => Number(a.id) - Number(b.id),
    },
    {
        title: 'Chapter Title',
        dataIndex: 'name',
        key: 'name',
        width: 400,
    },
    {
        title: 'Score Obtained',
        dataIndex: 'scoreReceived',
        key: 'scoreReceived',
        sorter:  (a, b) => Number(a.scoreReceived) - Number(b.scoreReceived),
    },
    {
        title: 'Total Score',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'Percentage',
        dataIndex: '',
        key: 'percentage',
        render: (_, {scoreReceived, total}) => `${((Number(scoreReceived) / Number(total)) * 100).toFixed(2)}%`,
    },
];

const UserScore: FC<UserScoreProps> = () => {

    const {token} = theme.useToken();

    const {
        data: scoreDetails,
    } = useGetScoreDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const collapseItems: CollapseProps['items'] = useMemo(() => {
        if (scoreDetails && scoreDetails.length > 0) {
            return scoreDetails.map((course, idx) => ({
                key: idx,
                label: (
                    <Typography className='text-2xl'>
                        {capitalize(course.courseName)}
                    </Typography>
                ),
                children: (
                    <Table<TopicScoreInterface>
                        dataSource={course.topics}
                        columns={columns}
                        pagination={false}
                        rowKey='id'
                    />
                ),
                style: {
                    marginBottom: 24,
                    background: token.colorFillAlter,
                    borderRadius: token.borderRadiusLG,
                    border: 'none',
                }
            }))
        }
        return [];
    }, [scoreDetails, token]);

    if (collapseItems.length === 0) {
        return (
            <Typography className='text-l'>
                You haven't completed any topics yet.
            </Typography>
        )
    }

    return (
        <Collapse
            bordered={false}
            size='large'
            expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
            style={{background: '#f0f0f0'}}
            items={collapseItems}
        />
    );
};

export default UserScore;