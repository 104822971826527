import React, { FC } from "react";
import { Button, Typography } from "antd";
import resume from "./images/Resume.svg";
import { useNavigate } from "react-router-dom";

interface ResumeProfileCardProps {
	resumeName: string;
	isInterviewTaken: boolean;
	setIsResumeAlreadyUploaded: (newState: string) => void;
}

const ResumeCard: FC<ResumeProfileCardProps> = ({resumeName, isInterviewTaken, setIsResumeAlreadyUploaded}) => {
  	const navigate = useNavigate();
  
  	return (
    	<>
			<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
				<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
					Resume
				</Typography.Title>
				<div className="bg-secondry-100 flex flex-row gap-10 items-center border border-dashed border-primary rounded-lg p-6 mt-4">
					<img src={resume} className="md:w-[75px] md:h-20 w-14 h-16" alt="resume" />
					<div className="flex flex-col justify-center items-center gap-3">
						<Typography.Text className="font-poppins text-secondry-400 md:text-base text-xs font-medium leading-normal">
							{resumeName}
						</Typography.Text>
						{isInterviewTaken ? (
								<Button
									className="font-poppins text-secondry-200 text-sm font-normal leading-normal bg-secondry-100 border border-solid border-secondry-200 rounded-lg h-8"
									disabled={true}
								>
									Update Resume
								</Button>
							) : (
								<Button
									className="font-poppins text-primary text-sm font-normal leading-normal bg-secondry-100 border border-solid border-primary rounded-lg h-8"
									onClick={() => {navigate('/'); setIsResumeAlreadyUploaded('false'); localStorage.setItem('status', '');}}
								>
									Update Resume
								</Button>
							)
						}
					</div>
				</div>
			</div>
    	</>
  	);
};

export default ResumeCard;
