import React, {FC, useEffect, useState} from 'react';
import {Button, Form, Input, Modal, notification, Typography} from "antd";
import {useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

interface ResetPasswordPayload {
    password: string;
}
interface ResetPasswordFormProps {
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = () => {
    const [form] = Form.useForm<ResetPasswordPayload>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const query = new URLSearchParams(useLocation().search);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const onClick = () => {
        navigate('/auth/login');
    }

    const onFinish = (values: ResetPasswordPayload) => {
        setIsLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}auth/user/forgot-password`,
                {
                    session_token: token,
                    email: email,
                    password: values.password,
                    security_code: 'code_lol',
                },
            )
            .then((response) => {
                if (response) {
                    form.resetFields();
					setModalOpen(true);
					setIsLoading(false);
                }
            })
            .catch((error) => {
                const msg = error.response.data.detail === 'Not found.' ? 'Email is not found' : '';
                api.error({
                    message: msg,
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setToken(query.get('token'));
        setEmail(query.get('value'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
			<div className='lg:w-[45%] w-full flex flex-col justify-center items-center xl:pl-16 lg:py-0 py-6 bg-white'>
				<div className='md:w-[425px] w-[90%] flex flex-col justify-center'>
					<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-2xl text-lg font-semibold leading-10 tracking-[0.24px] m-0'>
                        Reset Password
					</Typography.Title>
					<Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-6 tracking-[0.16px]'>
                        Please create password must contain 8 letters,1 Upper case A, Lower case a,Special character $
					</Typography.Text>
                    <Form
                        size='large'
                        name='resetPassword'
                        className='login-form lg:mt-10 mt-6'
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input.Password className='font-poppins lg:h-12 h-11'/>
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your confirm password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password className='font-poppins lg:h-12 h-11'/>
                        </Form.Item>

                        <Form.Item className='lg:mt-16 mt-5 mb-0'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                loading={isLoading}
                                className='font-poppins text-white text-base font-semibold leading-5 bg-primary rounded-lg lg:h-12 h-11'
                            >
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>
				</div>
			</div>
            <Modal
				width={isMobile ? 330 : 545}
				centered
				open={modalOpen}
				closable={true}
				className="rounded-2xl"
				onCancel={() => {
					setModalOpen(false);
				}}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<div className="text-center flex flex-col justify-center items-center">
					<img src="/assets/sucess-thumb-image.svg" className='md:w-[180px] md:h-[180px] w-[72px] h-[72px]' alt="thumb" />
					<Typography.Title className='font-poppins text-secondry-400 md:text-2xl text-lg font-medium leading-normal tracking-[1px] m-0'>
                        Successfully Changed Password
					</Typography.Title>
					<Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-normal tracking-[1px] lg:mt-6 mt-4'>
                    Proceed to login page and enter new password
					</Typography.Text>
                    <Button
                        block
                        type='primary'
                        className='font-poppins text-white text-base font-semibold leading-6 bg-primary w-60 h-12 mt-8'
                        onClick={onClick}
                    >
                        Go to Login
                    </Button>
				</div>
			</Modal>
        </>
    );
}

export default ResetPasswordForm;
