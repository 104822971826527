import React, {FC} from 'react';
import {cyan, yellow} from "@ant-design/colors";
import {Button, ButtonProps, ConfigProvider, theme} from "antd";

export type AIButtonVariants = 'primary' | 'cyan' | 'yellow'

export interface AiButtonProps extends ButtonProps {
    variant?: AIButtonVariants
}

const AiButton: FC<AiButtonProps> = ({
                                         variant = 'primary',
                                         type = 'primary',
                                         children,
                                         ...rest
                                     }) => {

    const VariantColorMapping: Record<AIButtonVariants, string | undefined> = {
        primary: theme.getDesignToken().colorPrimary,
        cyan: cyan.primary,
        yellow: yellow.primary,
    }

    return (
        <ConfigProvider
            theme={{
                token: {colorPrimary: VariantColorMapping[variant]}
            }}
        >
            <Button type={type} {...rest}>
                {children}
            </Button>
        </ConfigProvider>
    );
};

export default AiButton;
