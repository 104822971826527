import {IconType} from "antd/es/notification/interface";
import {Key, ReactNode} from "react";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./index";

export interface NotificationState {
	open: boolean
	message: ReactNode
	type: IconType
	key?: Key
}

const initialState: NotificationState = {
	open: false,
	type: 'success',
	message: '',
	key: Math.random(),
}

export interface GeneralPayload extends Omit<NotificationState, 'open' | 'type'> {
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		successNotification: (state, action: PayloadAction<GeneralPayload>) => {
			state.open = true;
			state.type = 'success';
			state.message = action.payload.message;
			state.key = action.payload?.key || Math.random();
		},
		errorNotification: (state, action: PayloadAction<GeneralPayload>) => {
			state.open = true;
			state.type = 'error';
			state.message = action.payload.message;
			state.key = action.payload?.key || Math.random();
		},
		infoNotification: (state, action: PayloadAction<GeneralPayload>) => {
			state.open = true;
			state.type = 'info';
			state.message = action.payload.message;
			state.key = action.payload?.key || Math.random();
		},
		warningNotification: (state, action: PayloadAction<GeneralPayload>) => {
			state.open = true;
			state.type = 'warning';
			state.message = action.payload.message;
			state.key = action.payload?.key || Math.random();
		},
		closeNotification: (state) => {
			state.open = false;
			state.type = initialState.type;
			state.message = initialState.message;
			state.key = initialState.key;
		}

	}
});

export default notificationSlice.reducer;

export const {
	successNotification,
	infoNotification,
	warningNotification,
	errorNotification,
	closeNotification
} = notificationSlice.actions;

export const notificationSelectors = {
	getNotificationDetails: (state: RootState) => state.notification,
}