import {api} from "./index";
import {
  ChatInterface,
  CourseIdPayload,
  GetChatHistoryResponse,
  SnakeCaseKeys,
  TopicIdPayload,
  TopicStatus
} from "../types";
import {camelToSnakeCase, snakeToCamelCase} from "../../utils/caseConvertor";

interface CompileCodeResponse {
  compiledOutput: string
}

interface CompileCodePayload {
  code: string
  language: string
}

interface UpdateTopicPayload extends CourseIdPayload, TopicIdPayload {
  status: TopicStatus
}

interface UpdateKidsTopicPayload extends CourseIdPayload, TopicIdPayload {
  status: TopicStatus
  kidsId: string
}

interface GetChatHistoryPayload extends CourseIdPayload, TopicIdPayload {
}

interface GetKidsChatHistoryPayload extends CourseIdPayload, TopicIdPayload {
  kidsId: string
}

interface SendChatPayload extends CourseIdPayload, TopicIdPayload {
  message: string
}

interface SendKidsChatPayload extends CourseIdPayload, TopicIdPayload {
  message: string
  kidsId: string
}

export const topicApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateTopicStatus: builder.mutation<{ message: string }, UpdateTopicPayload>({
      query: ({courseId, topicId, status}) => ({
        url: `api/topic-courses/${courseId}/topic/${topicId}/`,
        method: 'PUT',
        body: {
          status,
        }
      }),
    }),
    compileCode: builder.mutation<CompileCodeResponse, CompileCodePayload>({
      query: (payload) => ({
        url: 'api/compile-code/',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: SnakeCaseKeys<CompileCodeResponse>) => ({
        compiledOutput: response.compiled_output,
      }),
    }),
    getChatHistory: builder.query<GetChatHistoryResponse, GetChatHistoryPayload>({
      query: ({courseId, topicId}) => ({
        url: `api/courses/${courseId}/topics/${topicId}/chats`,
        credentials: 'include'
      }),
      transformResponse: (response: { data: SnakeCaseKeys<ChatInterface>[], status: GetChatHistoryResponse['status']}) => ({
        data: snakeToCamelCase(response.data),
        status: response.status,
      }),
    }),
    sendChat: builder.mutation<any, SendChatPayload>({
      query: (payload) => ({
        url: 'api/chats/',
        method: 'POST',
        credentials: 'include',
        body: {...camelToSnakeCase([payload])[0]},
      })
    }),
    updateKidsTopicStatus: builder.mutation<{ message: string }, UpdateKidsTopicPayload>({
      query: ({courseId, topicId, status, kidsId}) => ({
        url: `api/kids/${courseId}/topic/${topicId}/`,
        method: 'PUT',
        params: { kids_id: kidsId },
        body: {
          status,
        }
      }),
    }),
    getKidsChatHistory: builder.query<GetChatHistoryResponse, GetKidsChatHistoryPayload>({
      query: ({courseId, topicId, kidsId}) => ({
        url: `api/kids/${courseId}/topics/${topicId}/chats`,
        credentials: 'include',
        params: { kids_id: kidsId },
      }),
      transformResponse: (response: { data: SnakeCaseKeys<ChatInterface>[], status: GetChatHistoryResponse['status']}) => ({
        data: snakeToCamelCase(response.data),
        status: response.status,
      }),
    }),
    sendKidsChat: builder.mutation<any, SendKidsChatPayload>({
      query: (payload) => ({
        url: 'api/kids/chats/',
        method: 'POST',
        credentials: 'include',
        body: {...camelToSnakeCase([payload])[0]},
      })
    }),
  })
});

export const {
  useUpdateTopicStatusMutation,
  useCompileCodeMutation,
  useGetChatHistoryQuery,
  useSendChatMutation,
  useUpdateKidsTopicStatusMutation,
  useGetKidsChatHistoryQuery,
  useSendKidsChatMutation,
} = topicApi;
