import React, { FC } from 'react'
import { Typography } from 'antd'

interface AdminHeaderProps {
}

const AdminHeader: FC<AdminHeaderProps> = () => {
	return (
		<div className='xl:px-12 md:px-9 px-6 xl:py-10 py-3 bg-secondry-100'>
            <Typography.Title className='font-poppins text-secondry-400 md:text-2xl text-base font-semibold leading-normal xl:tracking-[1px] m-0'>
                Users
            </Typography.Title>
        </div>
	)
}

export default AdminHeader
