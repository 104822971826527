import React, {FC, useEffect} from 'react';
import {useAppSelector} from "../../store";
import {authSelectors} from "../../store/auth.slice";
import {useNavigate} from "react-router-dom";
import logo from '../../images/logo.png';
import SignUpForm from './SignUpForm';
import { useMediaQuery } from 'react-responsive';

interface SignUpPageProps {
}

export const SignUpPage: FC<SignUpPageProps> = () => {
    const navigate = useNavigate();

    const isLoggedIn = useAppSelector(authSelectors.getIsLoggedIn);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

    useEffect(() => {
        if (isLoggedIn) navigate('/');
    }, [navigate, isLoggedIn]);
    return (
        <div className='h-screen w-full'>
            <div className='w-full h-full'>
                <div className='flex lg:flex-row flex-col'>
                    <div className='lg:w-[55%] w-full lg:h-full h-[200px] flex'>
                        {isTabletOrMobile ? (
                            <div className='bg-white flex justify-center items-center w-full'>
                                <div className='bg-primary p-4 w-fit rounded-lg'>
                                    <img src={logo} alt="logo" onClick={() => navigate('/')} className='w-[150px] xl:h-24' />
                                </div>
                            </div>
                        ) : (
                            <div
                                className='relative lg:h-screen w-full'
                                style={{backgroundImage: `url(/assets/signup-page.webp)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
                            >
                                <div className='absolute top-0 left-0 w-full h-full bg-[#00000040]'></div>
                                <div className='relative z-1'>
                                    <img src={logo} alt="logo" onClick={() => navigate('/')} className='xl:w-[273px] lg:w-[200px] xl:h-24 xl:pt-16 xl:pl-16 pt-4 pl-5' />
                                </div>
                            </div>
                        )}
                    </div>
                    <SignUpForm/>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage;
