import React, {FC, useRef, useState} from 'react';
import Editor, {Monaco} from "@monaco-editor/react";
import {editor} from "monaco-editor";
import {Button, Card, Space, Typography} from "antd";
import {useParams} from "react-router-dom";
import {CourseInterface} from "../store/types";
import {useGetKidsCoursesQuery} from "../store/api/course.api";
import {useCompileCodeMutation, useGetKidsChatHistoryQuery} from "../store/api/topic.api";
import {COMMENTS} from "../utils/constants";

interface KidCodeEditorProps {
    updateEditorRef: (ref: editor.IStandaloneCodeEditor | null) => void
}

const KidCodeEditor: FC<KidCodeEditorProps> = ({updateEditorRef}) => {

    const {courseId, topicId} = useParams<{ courseId: string, topicId: string }>();
    const kids_id = localStorage.getItem('kids_user_id');

    const {
        data: chatHistory,
    } = useGetKidsChatHistoryQuery({
        courseId: Number(courseId),
        topicId: Number(topicId),
        kidsId: String(kids_id),
    }, {
        refetchOnMountOrArgChange: true,
    });

    const [
        compileCode,
        {
            isLoading: runCodeLoading,
            reset: runCodeReset,
            status: runCodeStatus,
        }
    ] = useCompileCodeMutation({
        fixedCacheKey: 'run-code-key',
    });

    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const [editorCode, setEditorCode] = useState('');

    const {currentCourse} = useGetKidsCoursesQuery(
        { kidsId: String(kids_id) },
        {
            selectFromResult: ({data}) => ({
                currentCourse: data?.find(c => c.courseId === Number(courseId)) || {} as CourseInterface,
            })
        }
    );

    const handleEditorOnMount = (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
        editorRef.current = editor;
        updateEditorRef(editor);
        monaco.editor.setModelLanguage(editor.getModel() as editor.ITextModel, currentCourse.courseName);
        setEditorCode(COMMENTS[currentCourse.courseName] || '');
    }

    const handleOnChange = (code: string | undefined, ev: editor.IModelContentChangedEvent) => {
        setEditorCode(code || '');
        if (runCodeStatus === 'fulfilled') {
            runCodeReset();
        }
    }

    const runCode = () => {
        if (!Boolean(currentCourse.courseName)) return;
        compileCode({
            code: editorCode,
            language: currentCourse.courseName
        });
    }

    return (
        <Card
            title='Code Editor'
            className='flex flex-col flex-1'
            bodyStyle={{flex: 1}}
            extra={
                <Space>
                    <Typography.Text className='capitalize'>
                        {currentCourse?.courseName || ''}
                    </Typography.Text>
                    <Button
                        type='primary'
                        onClick={runCode}
                        loading={runCodeLoading}
                        disabled={runCodeLoading || editorCode.length === 0 || chatHistory?.status === 'Done'}
                    >
                        Run
                    </Button>
                </Space>
            }
        >
            {currentCourse.courseName && (
                <Editor
                    height='100%'
                    language={currentCourse.courseName}
                    value={editorCode}
                    onMount={handleEditorOnMount}
                    onChange={handleOnChange}
                    theme='vs-dark'
                />
            )}
        </Card>
    );
};

export default KidCodeEditor;
