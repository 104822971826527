import {configureStore} from '@reduxjs/toolkit'
import {api} from "./api";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import auth from './auth.slice';
import notification from './notification.slice';
import usDetailsSlice from './usDetailsSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth,
		notification,
        usDetails: usDetailsSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
