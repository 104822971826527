import React, {ChangeEvent, FC, useMemo, useState, useEffect} from "react";
import {Col, Divider, Empty, Input, Row, Typography} from "antd";
import {ColSpan} from "../store/types";
import {useGetCoursesQuery} from "../store/api/course.api";
import CourseCard from "../components/CourseCard";
import CourseCardSkeleton from "../components/loaders/CourseCardSkeleton";
interface HomePageProps {
    checkResumeUploaded: () => void;
}

const colSpan: ColSpan = {
    xxl: 4,
    xl: 4,
    lg: 6,
    md: 8,
    sm: 12,
    xs: 12,
}

const HomePage: FC<HomePageProps> = ({checkResumeUploaded}) => {

    const {data: courses, isLoading} = useGetCoursesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [searchText, setSearchText] = useState("");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }

    const filteredCourses = useMemo(() => {
        if (courses && courses.length !== 0) {
            return courses.filter(c => c.courseName.toLowerCase().includes(searchText.toLowerCase()))
        }
        return [];
    }, [searchText, courses]);

    useEffect(() => {
        checkResumeUploaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="flex justify-center items-center flex-col xl:pt-[54px] pt-[98px]">
            <div className="w-[80%] flex justify-center items-center flex-col">
                <Typography.Title className="text-6xl font-bold mb-8">
                    Welcome to the AI Tutor
                </Typography.Title>
                <Typography.Text className="text-center text-xl">
                    "An AI tutor is a guide that empowers minds with the wisdom of
                    algorithms, sparking curiosity, and lighting the path to knowledge,
                    one line of code at a time.
                </Typography.Text>
                <Divider plain/>
            </div>
            <div className="w-[80%]">
                <Input.Search
                    allowClear
                    placeholder="Search"
                    color='primary'
                    size="large"
                    value={searchText}
                    onChange={handleChange}
                />
            </div>
            <div className="w-[80%] mt-8">
                <Row gutter={[24, 24]}>
                    {isLoading ? (
                        Array.from({length: 5}).map((_, idx) => (
                            <Col {...colSpan} key={`course-loader-${idx}`}>
                                <CourseCardSkeleton/>
                            </Col>
                        ))
                    ) : (
                        <>
                            {filteredCourses.length > 0 ? (
                                <>
                                    {
                                        filteredCourses.map((course, idx) => (
                                            <Col {...colSpan} key={`course-${idx}`}>
                                                <CourseCard course={course}/>
                                            </Col>
                                        ))
                                    }
                                </>
                            ) : (
                                <Col span={24}>
                                    <Empty description='No courses found'/>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </div>
        </section>
    );
};

export default HomePage;
