import React, { FC, useEffect, useState } from 'react'
import AdminHeader from './AdminHeader';
import { Divider, Dropdown, Input, Pagination, Progress, Typography } from 'antd';
import { ArrowRightOutlined, DownOutlined, FilterOutlined } from '@ant-design/icons';
import type { MenuProps, PaginationProps } from 'antd';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";

interface DashboardProps {
}

interface DataType {
	count: number
	email: string
	user_id: number
	intreview_taken: string
	domain: string
	experience: string
	score: number
}

const Dashboard : FC<DashboardProps> = () => {
	const applyColorPercentageBased = (percentage: any) => {
		if (percentage <= 25) {
			return '#DA0C0C'
		} else if (percentage <= 50) {
			return '#E59700'
		} else if (percentage <= 75) {
			return '#D3E500'
		} else if (percentage <= 90) {
			return '#5AA000'
		} else if (percentage <= 100) {
			return '#72CA00'
		} else {
		  return '#2D2D2D'
		}
	}

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: '1st menu item',
		},
		{
			key: '2',
			label: '2nd menu item',
		},
		{
			key: '3',
			label: '3rd menu item',
		},
	];

	const onChange: PaginationProps['onChange'] = (page, pageSize) => {
		setPageNumber(page);
		setShowEntries(pageSize);
		fetchDashboardData(page, pageSize);
		const startItem = (page - 1) * pageSize + 1;
      	const endItem = Math.min(page * pageSize, totalSize);
		const newRange: [number, number] = [startItem, endItem];
    	setRange(newRange);
	}

	const scorePercentage = (score: number) => {
		return parseFloat((score * 100).toFixed(2))
	}

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px)" });
	const { Search } = Input;
	const [dataSource, setDataSource] = useState<DataType[]>([{
		count: 0,
		email: '',
		user_id: 0,
		intreview_taken: '',
		domain: '',
		experience: '',
		score: 0,
	}]);
	const [pageNumber, setPageNumber] = useState(1);
	const [showEntries, setShowEntries] = useState(5);
	const [totalSize, setTotalSize] = useState(0);
	const [range, setRange] = useState<[number, number]>([1, showEntries]);
	const config = {
		headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
	};

	const fetchDashboardData = (page: number, pageSize: number) => {
		axios
		  	.get(
				`${process.env.REACT_APP_BASE_URL}api/admin/dashboard`,
				{
					params: {
						page_number: page,
						page_size: pageSize,
					},
					...config,
				}
		  	)
		  	.then((response) => {
				setDataSource(response.data.data);
				setTotalSize(response.data.total_size);
				localStorage.setItem("sessionKey", response.data.session_id);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		fetchDashboardData(pageNumber, showEntries);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='xl:pt-0 pt-16 w-100'>
				<AdminHeader/>
				<div className='bg-white h-full xl:px-12 md:px-9 px-6 md:py-6 py-4'>
					<div className='bg-secondry-100 px-4 py-2 rounded-lg flex items-center xl:mb-4'>
						<div className='flex gap-4 xl:w-[10%] lg:w-[12%] md:w-[18%] w-[38%]'>
							<Dropdown className='' menu={{ items }} placement="bottomLeft" arrow>
								<Typography className='font-poppins text-secondry-300 text-base font-normal leading-normal'><FilterOutlined className='text-base'/> Filter <DownOutlined className='ml-2 text-sm'/></Typography>
							</Dropdown>
						</div>
						<Divider type='vertical'/>
						<Search placeholder='Search for candidates' onSearch={value => console.log(value)}/>
					</div>
					{
						isTabletOrMobile ? (
							<>
								<Typography className='font-poppins text-secondry-300 text-base font-normal leading-normal my-3'>Showing {range[0]} - {range[1]} out of {totalSize} users</Typography>
								<div className='flex md:flex-row md:flex-wrap flex-col gap-3'>
									{dataSource.map((data, index) => (
										<div key={index} className='lg:w-[46%] md:w-[44%] w-100 bg-secondry-100 rounded-lg p-4'>
											<div className='flex flex-col'>
												<Typography className='font-poppins text-secondry-400 text-sm font-normal leading-normal'>{ data.email }</Typography>
												<Divider className='mt-2 m-0'/>
												<div className='flex justify-between mt-4'>
													<div className='flex flex-col'>
														<Typography className='font-poppins text-secondry-300 text-sm font-medium leading-normal'>Interviewed:</Typography>
														<Typography className='font-poppins text-secondry-400 text-sm font-normal leading-normal'>{ data.intreview_taken }</Typography>
													</div>
													<div className='flex flex-col'>
														<Typography className='font-poppins text-secondry-300 text-sm font-medium leading-normal'>Experience:</Typography>
														<Typography className='font-poppins text-secondry-400 text-sm font-normal leading-normal'>{ data.experience }</Typography>
													</div>
													<div className='flex flex-col'>
													<Typography className='font-poppins text-base font-normal leading-normal xl:tracking-[0.256px]' style={{ color: applyColorPercentageBased(scorePercentage(data.score)) }}>{ scorePercentage(data.score) }%</Typography>
													<Progress className='w-12' percent={scorePercentage(data.score)} showInfo={false} strokeColor={applyColorPercentageBased(scorePercentage(data.score))}/>
													</div>
												</div>
												<div className='flex justify-between items-center mt-5'>
													<div className='flex flex-col'>
														<Typography className='font-poppins text-secondry-300 text-sm font-medium leading-normal'>Domain:</Typography>
														<Typography className='font-poppins text-secondry-400 text-sm font-normal leading-normal'>{ data.domain }</Typography>
													</div>
													<a href={`user-detail/${data.user_id}`} className='font-poppins text-primary text-base font-semibold leading-normal'>View <ArrowRightOutlined/></a>
												</div>
											</div>
										</div>
									))}
								</div>
								<div className='fixed bottom-0 left-0 right-0 md:pr-4 py-4 bg-secondry-100 w-full md:text-right text-center'>
									<Pagination
										showSizeChanger
										current={pageNumber}
										defaultCurrent={pageNumber}
										defaultPageSize={showEntries}
										total={totalSize}
										onChange={onChange}
									/>
								</div>
							</>
						) : (
							<>
								<div className='flex flex-col w-100 bg-secondry-100 xl:rounded-lg'>
									<div className='flex p-6' style={{ borderBottom: '1px solid #DCDCDC'}}>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[8%]'>Sr No.</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[17%]'>Name</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[16%]'>Interviewed</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[30%]'>Domain</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[14%]'>Experience</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[9%]'>Score</Typography>
										<Typography className='font-poppins text-secondry-300 text-base font-medium leading-normal w-[6%]'>Details</Typography>
									</div>
									{
										dataSource.map((data, index) => (
											<div className='flex px-6 py-4 justify-between items-center hover:bg-white' key={index} style={{ borderBottom: index === (Math.min(showEntries, ((totalSize - (pageNumber * showEntries)) + showEntries)) - 1) ? '' : '2px solid rgba(0,85,164,0.05)' }}>
												<Typography className='font-poppins text-secondry-400 text-base font-normal leading-normal w-[8%]'>{ data.count }</Typography>
												<Typography className='font-poppins text-secondry-400 text-base font-normal leading-normal w-[17%]'>{ data.email }</Typography>
												<Typography className='font-poppins text-secondry-400 text-base font-normal leading-normal w-[16%]'>{ data.intreview_taken }</Typography>
												<Typography className='font-poppins text-secondry-400 text-base font-normal leading-normal w-[30%]'>{ data.domain }</Typography>
												<Typography className='font-poppins text-secondry-400 text-base font-normal leading-normal w-[14%]'>{ data.experience }</Typography>
												<div className='flex flex-col w-[9%]'>
													<Typography className='font-poppins text-base font-normal leading-normal xl:tracking-[0.256px]' style={{ color: applyColorPercentageBased(scorePercentage(data.score)) }}>{ scorePercentage(data.score) }%</Typography>
													<Progress className='w-12' percent={scorePercentage(data.score)} showInfo={false} strokeColor={applyColorPercentageBased(scorePercentage(data.score))}/>
												</div>
												<a href={`user-detail/${data.user_id}`} className='font-poppins text-primary text-base font-semibold leading-normal w-[6%]'>View <ArrowRightOutlined/></a>
											</div>
										))
									}
								</div>
								<div className='flex justify-between items-center mt-3'>
									<Typography className='font-poppins text-secondry-300 text-base font-normal leading-normal'>Showing {range[0]} - {range[1]} out of {totalSize} users</Typography>
									<Pagination
										showSizeChanger
										current={pageNumber}
										defaultCurrent={pageNumber}
										defaultPageSize={showEntries}
										total={totalSize}
										onChange={onChange}
									/>
								</div>
							</>
						)
					}
				</div>
			</div>
		</>
	)
}

export default Dashboard;
