import React, { FC, useState } from "react";
import { Button, Drawer} from "antd";
import {useNavigate} from "react-router-dom";
import Logo from "./Logo";
import {useAppSelector} from "../../store";
import {authSelectors} from "../../store/auth.slice";
import {MenuOutlined} from "@ant-design/icons";
import SidebarMenu from "./SidebarMenu";
import { useMediaQuery } from "react-responsive";
import logo from '../../images/logo.png';
import UserAvatar from "./UserAvatar";

interface HeaderProps {
    isUserAdmin: string;
}

const Header: FC<HeaderProps> = ({ isUserAdmin }) => {

    const navigate = useNavigate();
    const isLoggedIn = useAppSelector(authSelectors.getIsLoggedIn);
    
    const [openMenu, setOpenMenu] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });

    const openSidebar = () => {
		setOpenMenu(true);
	};

	const closeSidebar = () => {
		setOpenMenu(false);
	};

    return (
        <nav
            className='h-[76px] px-6 flex items-center gap-5 fixed z-50 backdrop-blur'
            style={{width: 'calc(100% - 40px)'}}
        >
            { isTabletOrMobile ? (
                <MenuOutlined style={{ fontSize: 24 }} onClick={() => {
                    openSidebar();
                }}/>
            ) : (
                <></>
            )}
            <Logo/>
            {!isLoggedIn ? (
                <div>
                    <Button shape="round" size='large' onClick={() => navigate('/auth/sign-up')}>
                        Sign Up/ LogIn
                    </Button>
                </div>
            ) : (
                <></>
            )}

            <div className='h-screen'>
                <Drawer
                    placement='left'
                    width='250px'
                    open={openMenu}
                    onClose={() => {
                        closeSidebar();
                    }}
                    closable={false}
                    className="bg-primary text-center"
                >
                    <img
                        src={logo}
                        className='w-40'
                        onClick={() => { navigate('/') }}
                        alt="AI Tutor"
                    />
                    <UserAvatar/>
                    <SidebarMenu isUserAdmin={isUserAdmin} closeSidebar={closeSidebar}/>
                </Drawer>
            </div>
        </nav>
    );
};

export default Header;
