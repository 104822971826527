import React, { FC, useEffect, useState } from 'react';
import { Button, Select, Typography, notification } from 'antd';
import upload from '../../images/upload.png';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';

interface ResumeUploadProps {
    setStatus: (newState: string) => void;
    setUploadedResume: (newState: string) => void;
    currentJobProfile: string;
    isUserValidForInterview: boolean;
}

const ResumeUpload: FC<ResumeUploadProps> = ({setStatus, currentJobProfile, setUploadedResume, isUserValidForInterview}) => {
    const [error, setError] = useState('');
    const [text, setText] = useState(currentJobProfile);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');
    const [uploadResumeLoading, setUploadResumeLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    };

    const jobProfiles = [
        {
            value: "Intern Full Stack Web Developer",
            label: "Intern Full Stack Web Developer",
        },
        {
            value: "Intern Machine Learning Engineer",
            label: "Intern Machine Learning Engineer",
        },
        {
            value: "Full Stack Web Developer",
            label: "Full Stack Web Developer",
        },
        {
            value: "Machine Learning Engineer",
            label: "Machine Learning Engineer",
        },
        {
            value: "Senior Full Stack Engineer",
            label: "Senior Full Stack Engineer",
        },
        {
            value: "Senior Data Scientist",
            label: "Senior Data Scientist",
        },
        {
            value: ".NET Developer",
            label: ".NET Developer",
        },
    ];

    const handleDrop = (event: any) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile && droppedFile.type === 'application/pdf') {
            setError('');
            setFile(droppedFile);
            setFileName(droppedFile.name);
        } else {
            setError('Upload only PDF file.');
        }
    };
    
    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleClick = () => {
        document.getElementById('upload-resume')?.click();
    }

    const handleFile = (event: any) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setError('');
            setFile(file);
            setFileName(file.name);
        } else {
            setError('Upload only PDF file.');
        }
    }

    const uploadResume = async () => {
        if (file) {
            try {
                setUploadResumeLoading(true);
                const formData = new FormData();
                formData.append('pdfFile', file);

                await axios
                        .post(`${process.env.REACT_APP_BASE_URL}api/interview/upload/`, formData, config)
                        .then((response) => {
                            console.log('File uploaded successfully:', response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                            setUploadResumeLoading(false);
                            api.error({
                                message: error.response.data.error,
                            });
                        });

                axios
                    .get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
                    .then((response) => {
                        const data = response.data.data;
                        setStatus(data.status);
                        localStorage.setItem('status', data.status);
                        setUploadedResume('true');
                        localStorage.setItem('resumeUploaded', 'true');
                        setUploadResumeLoading(false);

                        axios
                            .post(
                                `${process.env.REACT_APP_BASE_URL}api/interview/interview-details/`,
                                {
                                    field: text,
                                },
                                config
                            )
                            .then((response) => {
                                console.log(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setStatus('');
                        localStorage.setItem('status', '');
                        setUploadedResume('false');
                        localStorage.setItem('resumeUploaded', 'false');
                    });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    useEffect(() => {
        if (currentJobProfile) {
            setText(currentJobProfile);
        }
    }, [currentJobProfile]);

    return (
        <>
            { contextHolder }
            <div className='xl:mt-16 md:mt-10 mt-6 w-full'>
                <Typography.Title level={2} className='font-poppins text-secondry-400 md:text-base text-sm font-medium leading-normal xl:tracking-[1px] m-0'>
                    Job Profile
                </Typography.Title>
                <Select
                    value={text}
                    className='lg:w-[50%] w-full font-poppins text-secondry-300 md:h-12 h-11 border border-solid border-secondry-200 rounded-lg mt-3 mb-10'
                    onChange={(value) => setText(value)}
                    options={jobProfiles}
                />
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={handleClick}
                    className='xl:h-[360px] md:h-[230px] h-[180px] flex flex-col justify-center items-center bg-secondry-100 px-2 py-10 border border-dashed border-primary rounded-2xl'>
                    <input type="file" id='upload-resume' onChange={handleFile} hidden/>
                    <img src={upload} className='lg:w-[100px] lg:h-[100px] md:w-[80px] md:h-[80px] w-[56px] h-[56px]' alt="Upload Resume" />
                    <Typography.Text className='font-poppins text-secondry-300 md:text-2xl text-lg font-semibold leading-6 md:tracking-[0.24px] tracking-[0.18px] mt-6'>
                        Upload your resume
                    </Typography.Text>
                    <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-medium leading-6 md:tracking-[0.16px] tracking-[0.14px] mt-5'>
                        Click here or Drag & drop you resume
                    </Typography.Text>
                    {
                        error !== '' ? (
                            <Typography.Text className='font-poppins text-xs font-medium text-custom-red mt-4'>
                                {`**${ error }`}
                            </Typography.Text>
                        ) : (
                            <Typography.Text className='font-poppins text-xs font-medium text-secondry-300 mt-4'>
                                {fileName}
                            </Typography.Text>
                        )
                    }
                </div>
                <div className='md:mt-10 mt-[72px] text-center'>
                    <Button type="primary" className="font-poppins text-white text-base font-semibold leading-6 bg-primary md:h-12 h-11 md:w-fit w-full" loading={uploadResumeLoading} onClick={uploadResume} disabled={!isUserValidForInterview}><PlusOutlined/>Upload Resume</Button>
                </div>
            </div>
        </>
    )
}

export default ResumeUpload;
