import React, {FC, useEffect, useState} from 'react';
import {Button, Divider, Form, Input, notification, Typography} from "antd";
import {LoginPayload} from "../../store/types";
import {useLoginMutation} from "../../store/api/auth.api";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

interface LoginFormProps {
}

const LoginForm: FC<LoginFormProps> = () => {

    const [form] = Form.useForm<LoginPayload>();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [login, {isLoading, isSuccess}] = useLoginMutation();
    const [kidsLoding, setKidsLoding] = useState(false);

    const onFinish = async (values: LoginPayload) => {
        await login({...values});
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            form.resetFields();
            api.success({
                message: 'User logged in successfully'
            });
        }
    }, [form, isLoading, isSuccess, navigate, api]);

    const KidsApiCall = async () => {
        setKidsLoding(true);
        if (!localStorage.getItem('kids_user_id')) {
            const kids_id = uuidv4();
            localStorage.setItem('kids_user_id', kids_id);
    
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/kids/`, { "kids_id": kids_id });
                const data = response.data.data;
                console.log(data)
                setKidsLoding(false);
            } catch (error) {
                console.error(error);
            }
    
            navigate('/kids/courses/1');
        } else {
            navigate('/kids/courses/1');
        }
    };

    return (
        <>
            {contextHolder}
            <div className='lg:w-[45%] w-full flex flex-col justify-center items-center xl:pl-16 lg:py-0 py-6 bg-white'>
                <div className='md:w-[425px] w-[90%] flex flex-col justify-center'>
                    <Typography.Title className='font-poppins text-secondry-400 md:text-4xl text-2xl font-medium leading-10 tracking-[0.36px] m-0'>
                        Welcome back
                    </Typography.Title>
                    <div className='md:w-72 w-48'>
                        <Divider className='h-2 bg-primary rounded-[30px] m-0'/>
                    </div>
                    <Button
                        type='default'
                        loading={kidsLoding}
                        htmlType='submit'
                        className='font-poppins text-primary text-base font-semibold leading-5 border-primary rounded-lg lg:mt-14 mt-6 lg:h-12 h-11'
                        onClick={KidsApiCall}
                    >
                        Python For Kids
                    </Button>
                    <Typography.Title level={2} className='font-poppins text-secondry-400 md:text-2xl text-lg font-semibold leading-10 tracking-[0.24px] m-0 lg:mt-14 mt-6'>
                        Login
                    </Typography.Title>
                    <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal md:leading-10 leading-6 tracking-[0.16px]'>
                        Please enter your details to enter to the first page
                    </Typography.Text>
                    <Form
                        size='large'
                        name='login'
                        className='login-form lg:mt-10 mt-6'
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {required: true, message: 'Please input your email!'},
                                {type: 'email', message: 'Email is not valid'}
                            ]}
                        >
                            <Input className='font-poppins lg:h-12 h-11'/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input.Password className='font-poppins lg:h-12 h-11'/>
                        </Form.Item>
                        <div className='flex flex-row justify-end'>
                            <Link className='font-poppins text-primary text-sm font-normal leading-5 tracking-[0.14px]' to={'/auth/forgot-password'}>Forgot Password?</Link>
                        </div>
                        <Form.Item className='lg:mt-16 mt-[140px] mb-0'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                loading={isLoading}
                                className='font-poppins text-white text-base font-semibold leading-5 bg-primary rounded-lg lg:h-12 h-11'
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='flex justify-center lg:mt-10 mt-6'>
                        <Typography.Text className='font-poppins text-secondry-400 text-base font-medium leading-5 tracking-[0.16px]'>
                            Don’t have an account?, <Link to={'/auth/sign-up'} className='font-poppins text-primary text-base font-semibold leading-5 tracking-[0.16px]'>Sign up.</Link>
                        </Typography.Text>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginForm;
