import React, {FC} from 'react';
import {Badge, Card, Image, theme} from "antd";
import capitalize from "antd/es/_util/capitalize";
import {CourseInterface} from "../store/types";
import {useNavigate} from "react-router-dom";

interface CourseCardProps {
    course: CourseInterface
}

const CourseCard: FC<CourseCardProps> = ({course}) => {

    const navigate = useNavigate();
    const goToCourse = (course: CourseInterface) => {
        if (!course.enabled) return;
        navigate(`/courses/${course.courseId}`);
    }

    return (
        <Badge.Ribbon text={course.status} color='purple' className={course.enabled ? '' : 'hidden'}>
            <Card
                bordered
                onClick={() => goToCourse(course)}
                aria-disabled={!course.enabled}
                hoverable={course.enabled}
                className={[course.enabled ? '' : 'cursor-not-allowed brightness-75'].join(' ')}
                bodyStyle={{backgroundColor: theme.getDesignToken().colorPrimaryBg}}
                cover={
                    <div
                        className='!flex !w-auto justify-center align-middle p-5 rounded-t-lg'
                        style={{backgroundColor: theme.getDesignToken().colorPrimaryBgHover}}
                    >
                        <Image
                            preview={false}
                            alt="example"
                            src={`/assets/${course.courseName}.svg`}
                            className='h-[150px] aspect-square mx-auto'
                        />
                    </div>
                }
            >
                <Card.Meta title={capitalize(course.courseName)}/>
            </Card>
        </Badge.Ribbon>
    );
};

export default CourseCard;
