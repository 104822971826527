import React, {FC} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ColSpan, CourseInterface} from "../store/types";
import {Col, Empty, Row, Spin, Typography} from "antd";
import capitalize from "antd/es/_util/capitalize";
import {useGetCoursesQuery, useGetTopicsQuery} from "../store/api/course.api";
import TopicCard from "../components/TopicCard";
import TopicCardSkeleton from "../components/loaders/TopicCardSkeleton";
import AiButton from "../components/common/AIButton";

interface CourseProps {
}

const colSpan: ColSpan = {
    xxl: 8,
    xl: 8,
    lg: 12,
    md: 12,
    sm: 24,
    xs: 24,
}
const CoursePage: FC<CourseProps> = () => {

    const {courseId} = useParams<{ courseId: string }>();
    const navigate = useNavigate();

    const {
        currentCourse,
        isLoading: getCoursesLoading,
    } = useGetCoursesQuery(undefined,
        {
            selectFromResult: ({data, ...rest}) => ({
                currentCourse: data?.find(c => c.courseId === Number(courseId)) || {} as CourseInterface,
                ...rest,
            })
        }
    );

    const {
        isLoading: getTopicsLoading,
        data: topics,
    } = useGetTopicsQuery({courseId: Number(courseId)});

    if (getCoursesLoading) {
        return (
            <Row justify='center' className='text-center'>
                <Col span={24}>
                    <Spin/>
                </Col>
            </Row>
        )
    }

    if (!getCoursesLoading && currentCourse && !(currentCourse.enabled)) {
        return (
            <Row justify='center' className='text-center'>
                <Col span={24}>
                    <Typography.Title level={1} className='text-center'>
                        {capitalize(currentCourse.courseName || '')} Full Course
                    </Typography.Title>
                </Col>
                <Col span={24}>
                    <Typography.Title level={4}>
                        Opps!, Looks like the current course is disabled.
                    </Typography.Title>
                    <AiButton onClick={() => navigate('/courses')}>
                        Go To Dashboard
                    </AiButton>
                </Col>
            </Row>
        )
    }

    return (
        <Row justify='center' className='xl:pt-0 pt-16'>
            <Col span={24}>
                <Typography.Title level={1} className='text-center'>
                    {capitalize(currentCourse.courseName || '')} Full Course
                </Typography.Title>
            </Col>
            <Col span={24}>
                <div className='mx-auto w-[50vw] h-1 my-10 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full'/>
            </Col>
            <Col span={24}>
                <Typography.Title className='text-center'>
                    Chapters
                </Typography.Title>
            </Col>
            <Col span={22}>
                {getTopicsLoading ? (
                    <Row justify='start' gutter={[24, 24]} className='pt-4'>
                        {Array.from({length: 7}).map((_, idx) => (
                            <Col {...colSpan} key={`topic-skeleton-${idx}`}>
                                <TopicCardSkeleton/>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <>
                        {topics && topics.length !== 0 ? (
                            <Row
                                justify={topics.length < 4 ? 'center' : 'start'}
                                gutter={[32, 32]}
                                className='pt-4'
                            >
                                {topics.map((topic, idx) => (
                                    <Col {...colSpan} key={`topic-${idx}`}>
                                        <TopicCard topic={topic} idx={idx}/>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Empty description='No topics for course found'/>
                        )}
                    </>
                )}
            </Col>
            <Col span={24}>
                <div className='mx-auto w-[50vw] h-1 my-10 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full'/>
            </Col>
        </Row>
    );
};

export default CoursePage;
