/* eslint-disable */
import React, { FC, useEffect, useState } from "react";
import { Divider, Button, Tooltip, Typography } from "antd";
import ResumeCard from "./ResumeCard";
import HistoryCard from "./HistoryCard";
import NoHistoryCard from "./NoHistoryCard";
import JobPofileCard from "./JobProfileCard";
import ExperienceCard from "./ExperienceCard";
import SkillsCard from "./SkillsCard";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProjectsCard from "./ProjectsCard";
import InterviewHeader from "./InterviewHeader";
import ResumeFeedbackScoreCard from "./ResumeFeedbackScoreCard";

interface InterviewDetailsProps {
  setIsResumeAlreadyUploaded: (newState: string) => void;
}

const InterviewDetails: FC<InterviewDetailsProps> = ({setIsResumeAlreadyUploaded}) => {
	const [skills, setSkills] = useState([]);
	const [jobProfile, setJobProfile] = useState("");
	const [experience, setExperience] = useState([]);
	const [projects, setProjects] = useState([]);
	const [feedback, setFeedback] = useState([]);
	const [actualScore, setActualScore] = useState(0);
	const [totalScore, setTotalScore] = useState(0);
	const [nextInterview, setNextInterview] = useState("");
	const [resumeName, setResumeName] = useState("");
	const [history, setHistory] = useState([]);
	const [noExperience, setNoexperience] = useState(false);
	const [noSkills, setNoSkills] = useState(false);
	const [noProjects, setNoProjects] = useState(false);
	const [isInterviewTaken, setIsInterviewTaken] = useState(false);
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px)" });
	const [tooltipText, setTooltipText] = useState("");
	const config = {
		headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
	};
	const navigate = useNavigate();
  
  	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
			.then((response) => {
				const data = response.data.data;
				const validJsonString = data.skills
				.replace(/'/g, '"');
				setSkills(JSON.parse(validJsonString));
				if (JSON.parse(validJsonString).length === 0) {
					setNoSkills(true);
				}
				setJobProfile(data.field);
				const validJsonStringExp = data.experience
				.replace(/'/g, '"');
				setExperience(JSON.parse(validJsonStringExp));
				if (JSON.parse(validJsonStringExp).length === 0) {
					setNoexperience(true);
				}
				const validJsonStringProject = data.projects
				.replace(/'/g, '"');
				setProjects(JSON.parse(validJsonStringProject));
				if (JSON.parse(validJsonStringProject).length === 0) {
					setNoProjects(true);
				}
				setResumeName(data.resume_name);
			})
			.catch((error) => {
				console.log(error);
			});
    	axios
			.get(`${process.env.REACT_APP_BASE_URL}api/interview/history/`, config)
			.then((response) => {
				setHistory(response.data.data);
				setNextInterview(response.data.next_interview);
				setTooltipText(
					`Interview completed successfully`
				);
			})
			.catch((error) => {
				console.log(error);
			});
    	axios
			.get(
				`${process.env.REACT_APP_BASE_URL}api/interview/score-status/`,
				config
			)
			.then((response) => {
				const data = response.data.data;
				setIsInterviewTaken(data.isInterviewTaken);
				setFeedback(data.feedback);
				setActualScore(data.score_recieved);
				setTotalScore(data.total_score);
			})
			.catch((error) => {
				console.log(error);
				setFeedback([]);
				setActualScore(0);
				setTotalScore(0);
			});
	}, []);
	return (
		<>
			<div className='xl:pt-0 pt-16 w-100'>
				<InterviewHeader/>
				<div className='bg-white h-full xl:px-12 md:px-9 px-6 md:py-6 py-4'>
					<div className="md:flex md:flex-row md:justify-between md:items-center">
						<div>
							<Typography.Title className='font-poppins text-secondry-400 md:text-[22px] text-base font-medium leading-normal xl:tracking-[1px] m-0'>
								Your Interview Details
							</Typography.Title>
							<Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-normal xl:tracking-[1px]'>
								Your resume has been successfully uploaded!
							</Typography.Text>
							<div className='xl:w-[411px] md:w-[368px] w-[322px] mt-2'>
								<Divider className='h-1 bg-primary rounded-[30px] m-0'/>
							</div>
						</div>
						<div className="mr-2 pt-2">
							{isInterviewTaken ? (
								<>
									<Tooltip
										placement="top"
										title={tooltipText}
										color="#696969"
										key="#696969"
										className="font-poppins text-sm font-normal leading-6"
									>
										<Button
											type="primary"
											className="font-poppins text-white text-base font-semibold leading-6 bg-secondry-200 md:h-12 h-11 md:w-fit w-full"
											disabled={true}
										>
											Attend Interview
										</Button>
									</Tooltip>
								</>
							) : (
								<Button
									type="primary"
									className="font-poppins text-white text-base font-semibold leading-6 bg-primary md:h-12 h-11 md:w-fit w-full"
									onClick={() => navigate('/interview-chat')}
								>
									Attend Interview
								</Button>
							)}
						</div>
					</div>
					{isTabletOrMobile ? (
						<>
							{ feedback.length > 0 ? (
								<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl w-100 md:mt-10 mt-6">
									<Typography.Title className='font-poppins text-secondry-400 text-lg font-medium leading-normal mt-0'>
										Interview Details
									</Typography.Title>
									<div className='w-[150px] mt-2'>
										<Divider className='h-1 bg-primary rounded-[30px] m-0'/>
									</div>
									<ResumeFeedbackScoreCard feedbackList={feedback} actualScore={actualScore} totalScore={totalScore}/>
								</div>
							) : (
								<></>
							)}
							<div className={['flex flex-col items-center', feedback.length > 0 ? 'mt-3' : ' md:mt-7 mt-3'].join(' ')}>
								<div className="m-3 w-full">
									<ResumeCard resumeName={resumeName} isInterviewTaken={isInterviewTaken} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded} />
								</div>
								{history.length !== 0 ? (
								<>
									<div className="m-3 w-full">
										<HistoryCard
											currentHistory={history}
											nextInterview={nextInterview}
										/>
									</div>
								</>
								) : (
								<div className="m-3 w-full">
									<NoHistoryCard />
								</div>
								)}
								<div className="m-3 w-full">
									<JobPofileCard currentJobProfile={jobProfile} />
								</div>
								<div className="m-3 w-full">
									<ExperienceCard experience={experience} hasNoExperience={noExperience} setNoexperience={setNoexperience} setExperience={setExperience}/>
								</div>
								<div className="m-3 w-full">
									<SkillsCard initialSkills={skills} haveNoskills={noSkills} setNoSkills={setNoSkills} setSkills={setSkills}/>
								</div>
								<div className="m-3 w-full">
									<ProjectsCard initialProjects={projects} haveNoProjects={noProjects} setNoProjects={setNoProjects} setProjects={setProjects}/>
								</div>
							</div>
						</>
					) : (
						<>  
							{ feedback.length > 0 ? (
								<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl w-100 mt-10">
									<Typography.Title className='font-poppins text-secondry-400 text-lg font-medium leading-normal tracking-[1px] mt-0'>
										Interview Details
									</Typography.Title>
									<div className='w-[164px] mt-2'>
										<Divider className='h-1 bg-primary rounded-[30px] m-0'/>
									</div>
									<ResumeFeedbackScoreCard feedbackList={feedback} actualScore={actualScore} totalScore={totalScore}/>
								</div>
							) : (
								<></>
							)}
							<div className="flex flex-row gap-20">
								<div className={['flex flex-col w-[50%] items-center', feedback.length > 0 ? 'mt-3' : 'mt-7'].join(' ')}>
									<div className="m-3 w-full">
										<ResumeCard resumeName={resumeName} isInterviewTaken={isInterviewTaken} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded} />
									</div>
									{history.length !== 0 ? (
										<>
										<div className="m-3 w-full">
											<HistoryCard
												currentHistory={history}
												nextInterview={nextInterview}
											/>
										</div>
										</>
									) : (
										<div className="m-3 w-full">
											<NoHistoryCard />
										</div>
									)}
								</div>
								<div className={['flex flex-col w-[50%] items-center', feedback.length > 0 ? 'mt-3' : 'mt-7'].join(' ')}>
									<div className="m-3 w-full">
										<JobPofileCard currentJobProfile={jobProfile} />
									</div>
									<div className="m-3 w-full">
										<ExperienceCard experience={experience} hasNoExperience={noExperience} setNoexperience={setNoexperience} setExperience={setExperience}/>
									</div>
									<div className="m-3 w-full">
										<SkillsCard initialSkills={skills} haveNoskills={noSkills} setNoSkills={setNoSkills} setSkills={setSkills}/>
									</div>
									<div className="m-3 w-full">
										<ProjectsCard initialProjects={projects} haveNoProjects={noProjects} setNoProjects={setNoProjects} setProjects={setProjects}/>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default InterviewDetails;
