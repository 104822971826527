import React, { FC, useState } from 'react';
import { Button, Modal, Avatar } from 'antd';
import {CrownOutlined, UserOutlined } from "@ant-design/icons";

interface ChatMessage {
    user: string;
    chat: string;
    time: string;
}

interface ViewInterviewChatHistoryProps {
    chatHistory: ChatMessage[];
}

const ViewInterviewChatHistory: FC<ViewInterviewChatHistoryProps> = ({ chatHistory }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" className="font-poppins text-white text-base font-semibold leading-6 bg-primary md:h-12 h-11 md:w-fit w-1/2 mt-5" onClick={showModal}>
                View interview chat history
            </Button>
            <Modal
                title="Chat History"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                bodyStyle={{ padding: '20px' }}
                width={'70%'}
                style={{ top: '20px' }}
            >
                <div style={{ maxHeight: '70vh', overflowY: 'auto', padding:"30px" }}>
                    {chatHistory.map((item, index) => (

                        
                        <div key={index} style={{ display: 'flex', justifyContent: item.user === 'A' ? 'flex-start' : 'flex-end', marginBottom: '10px' }}>
                            {item.user === 'A' && (
                                <Avatar
                                    size="default"
                                    style={{ backgroundColor: "#090024", marginRight: '8px' }}
                                    icon={<CrownOutlined />}
                                />
                            )}
                            <div className="message" style={{ backgroundColor: item.user === 'A' ? '#f0f0f0' : '#e6f7ff', borderRadius: '8px', padding: '10px', maxWidth: '70%', textAlign: item.user === 'A' ? 'left' : 'right' }}>
                               <p style={{ margin: 0 }}>{item.chat}</p>
                            </div>
                            {item.user !== 'A' && (
                                <Avatar
                                    size="default"
                                    style={{marginLeft: '8px' }}
                                    icon={<UserOutlined />}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default ViewInterviewChatHistory;
