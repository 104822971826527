import React, {FC, useState} from 'react';
import {Button, Form, Input, Modal, notification, Typography} from "antd";
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

interface ForgotPasswordPayload {
    email: string;
}
interface ForgotPasswordFormProps {
}

export const ForgotPasswordPage: FC<ForgotPasswordFormProps> = () => {
	const [form] = Form.useForm<ForgotPasswordPayload>();
    const [isLoading, setIsLoading] = useState(false)
	const [modalOpen, setModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    
    const onFinish = async (values: ForgotPasswordPayload) => {
        setIsLoading(true);
        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}auth/user/forgot-password`,
                {
                    email: values.email
                },
            )
            .then((response) => {
				if (response) {
					form.resetFields();
					setModalOpen(true);
					setIsLoading(false);
				}
            })
            .catch((error) => {
                api.error({
                    message: error.response.data.non_field_errors,
                });
                setIsLoading(false);
            });
    };

	return (
		<>
			{contextHolder}
			<div className='lg:w-[45%] w-full flex flex-col justify-center items-center xl:pl-16 lg:py-0 py-6 bg-white'>
				<div className='md:w-[425px] w-[90%] flex flex-col justify-center'>
					<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-2xl text-lg font-semibold leading-10 tracking-[0.24px] m-0'>
						Forgot Password?
					</Typography.Title>
					<Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal md:leading-10 leading-6 tracking-[0.16px]'>
                        Please enter your email to recover your password
					</Typography.Text>
					<Form
                        size='large'
                        name='forgotPassword'
                        className='login-form lg:mt-10 mt-6'
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {required: true, message: 'Please input your email!'},
                                {type: 'email', message: 'Email is not valid'}
                            ]}
                        >
                            <Input className='font-poppins lg:h-12 h-11'/>
                        </Form.Item>

                        <Form.Item className='lg:mt-16 mt-24 mb-0'>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                loading={isLoading}
								className='font-poppins text-white text-base font-semibold leading-5 bg-primary rounded-lg lg:h-12 h-11'
                            >
                                Request Password reset link
                            </Button>
                        </Form.Item>
                    </Form>
				</div>
			</div>
			<Modal
				width={isMobile ? 330 : 545}
				centered
				open={modalOpen}
				closable={true}
				className="rounded-2xl"
				onCancel={() => {
					setModalOpen(false);
				}}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<div className="text-center flex flex-col justify-center items-center">
					<img src="/assets/thumb-image.svg" className='md:w-[180px] md:h-[180px] w-[72px] h-[72px]' alt="thumb" />
					<Typography.Title className='font-poppins text-secondry-400 md:text-2xl text-lg font-medium leading-normal tracking-[1px] m-0'>
						Reset Link has Sent Successfully
					</Typography.Title>
					<Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-normal tracking-[1px] lg:mt-6 mt-4'>
						Password link has been sent to your register Email please check
					</Typography.Text>
				</div>
			</Modal>
		</>
	)
}

export default ForgotPasswordPage;
