import React, { FC } from 'react'
import SidebarMenu from './SidebarMenu';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import UserAvatar from './UserAvatar';

interface SidebarProps {
    isUserAdmin: string;
}

const Sidebar: FC<SidebarProps> = ({isUserAdmin}) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className='pl-8 pt-4'>
            <img
                src={logo}
                className='w-36'
                onClick={() => { navigate('/') }}
                alt="AI Tutor"
            />
            </div>
            <UserAvatar/>

            <SidebarMenu isUserAdmin={isUserAdmin} closeSidebar={() => {}}/>
        </div>
    )
}

export default Sidebar;
