import React, {FC, useEffect} from 'react';
import {notification} from "antd";
import {useAppSelector} from "../../store/index";
import {notificationSelectors} from "../../store/notification.slice";

interface NotificationWrapperProps {
}

const NotificationWrapper: FC<NotificationWrapperProps> = () => {

	const [api, contextHolder] = notification.useNotification();

	const notificationDetails = useAppSelector(notificationSelectors.getNotificationDetails);

	useEffect(() => {
		const {
			open,
			type,
			message,
			key,
		} = notificationDetails;

		if (open) {
			api.open({
				type: type,
				message: message,
				key: key,
			});
		}
	}, [notificationDetails, api]);

	return (
		<>
			{contextHolder}
		</>
	);
};

export default NotificationWrapper;