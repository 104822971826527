import React, { FC } from "react";
import { Button } from "antd";

interface ResumeDownloadForAdminProps {
  resumeDown: Blob | null;
}

const ResumeDownload: FC<ResumeDownloadForAdminProps> = ({ resumeDown }) => {
  const downloadResume = () => {
    if (resumeDown && resumeDown.size > 0) {
      const url = URL.createObjectURL(resumeDown);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resume.pdf");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } else {
      console.error("Invalid or empty resume data.");
    }
  };

  return (
    <Button style={{ float: "right" }} onClick={downloadResume}>
      Download
    </Button>
  );
};

export default ResumeDownload;
