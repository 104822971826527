import React, { FC } from 'react'
import { Menu } from 'antd';
import {
    ContactsOutlined, AuditOutlined, LogoutOutlined, SnippetsOutlined, DashboardOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { logoutUser } from '../../store/auth.slice';
import { useMediaQuery } from 'react-responsive';

interface SidebarMenuProps {
    closeSidebar: () => void;
    isUserAdmin: string;
}

interface MenuDatatype {
    label: string;
    key: string;
    icon: React.ReactNode;
    redirectto: string;
    adminurl: boolean;
}

const SidebarMenu : FC<SidebarMenuProps> = ({ closeSidebar, isUserAdmin }) => {
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px) and (max-height: 948px)" });
const isTallerThan948 = useMediaQuery({ query: "(min-height: 949px)" });
const isShorterThan864 = useMediaQuery({ query: "(max-height: 863px)" });

let paddingTop;
if (isTabletOrMobile) {
    paddingTop = "12rem";
} else if (isTallerThan948) {
    paddingTop = "21rem";
} else if (isShorterThan864) {
    paddingTop = 0;
} else {
    paddingTop = "19rem";
}


    const onNavigate = (url: string) => {
        closeSidebar();
        navigate(url)
    }
    
    const menuItems: MenuDatatype[] = [
        {
            label: 'Interview',
            key: 'interview',
            icon: <AuditOutlined style={{ fontSize: 20 }} />,
            redirectto: '/',
            adminurl: false,
        },
        {
            label: 'Courses',
            key: 'courses',
            icon: <SnippetsOutlined style={{ fontSize: 20 }} />,
            redirectto: '/courses',
            adminurl: false,
        },
        {
            label: 'Profile',
            key: 'profile',
            icon: <ContactsOutlined style={{ fontSize: 20 }} />,
            redirectto: '/profile',
            adminurl: false,
        },
        {
            label: 'Dashboard',
            key: 'dashboard',
            icon: <DashboardOutlined style={{ fontSize: 20 }} />,
            redirectto: '/admin/dashboard',
            adminurl: true,
        }
    ];

    return (
        <div className='flex flex-col justify-between mt-8 pl-4' style={{height: 'calc(40vh - 150px)'}}>
            <Menu className='bg-transparent text-white text-base text-left w-100'>
                <span style={{ fontSize: 20 }}>Menu</span>
            </Menu>
            <Menu className='bg-transparent text-white text-base text-left w-100'>
                {menuItems.map((item) => (
                    (item.adminurl && isUserAdmin === 'true') ? (
                        <Menu.Item key={item.key} icon={item.icon} onClick={() => onNavigate(item.redirectto)}>
                            {item.label}
                        </Menu.Item>
                    ) : !item.adminurl && (
                        <Menu.Item key={item.key} icon={item.icon} onClick={() => onNavigate(item.redirectto)}>
                            {item.label}
                        </Menu.Item>
                    )
                ))}
            </Menu>
            <Menu className='bg-transparent text-white text-base text-left w-100 pt' style={{paddingTop}}>
                <Menu.Item
                    key='logout'
                    icon={<LogoutOutlined />}
                    onClick={ () => 
                        {
                            dispatch(logoutUser());
                            navigate('/auth/login');
                        }
                    }
                    className='menu-item'
                >
                    Logout
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default SidebarMenu;
