import React, { FC, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface ResumeInprogressProps {
    setStatus: (newState: string) => void;
    setIsResumeAlreadyUploaded: (newState: string) => void;
}

export const ResumeStatusInprogress : FC<ResumeInprogressProps> = ({setStatus, setIsResumeAlreadyUploaded}) => {
    const [statusUpdated, setStatusUpdated] = useState(true)
    const navigate = useNavigate();
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    };

    useEffect(() => {
        if (localStorage.getItem('resumeUploaded') === 'true' && statusUpdated) {
            const intervalId = setInterval(() => {
                axios
                .get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
                .then((response) => {
                    const data = response.data.data;
                    setStatus(data.status);
                    localStorage.setItem('status', data.status);
                    if (data.status === 'DONE') {
                        setStatusUpdated(false);
                        setIsResumeAlreadyUploaded('true');
                        navigate('/interview-detail');
                    }
                })
                .catch((error) => {
                    setStatus('');
                    localStorage.setItem('status', '');
                    console.log(error);
                });
            }, 10000);
            return () => clearInterval(intervalId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='xl:mt-32 md:mt-24 mt-16 w-full'>
            <div className='xl:h-[360px] md:h-[230px] h-[180px] flex flex-col justify-center items-center bg-secondry-100 px-2 py-10 border border-dashed border-primary rounded-2xl'>
                <LoadingOutlined className='text-secondry-300 text-5xl'/>
                <Typography.Text className='font-poppins text-secondry-300 md:text-2xl text-lg font-semibold leading-6 md:tracking-[0.24px] tracking-[0.18px] md:mt-8 mt-4'>
                    Analysing your resume
                </Typography.Text>
            </div>
        </div>
    )
}

export default ResumeStatusInprogress;
