import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import failed from '../../images/failed.png';
import { PlusOutlined } from '@ant-design/icons';

interface ResumeFailedProps {
    setStatus: (newState: string) => void;
}

export const ResumeStatusFailed : FC<ResumeFailedProps> = ({setStatus}) => {    
    return (
        <>
            <div className='xl:mt-32 md:mt-24 mt-16 w-full'>
                <div className='xl:h-[360px] md:h-[230px] h-[180px] flex flex-col justify-center items-center bg-secondry-100 px-2 py-10 border border-dashed border-primary rounded-2xl'>
                    <img src={failed} className='lg:w-[100px] lg:h-[100px] md:w-[80px] md:h-[80px] w-[56px] h-[56px]' alt="Upload Resume" />
                    <Typography.Text className='font-poppins text-custom-red md:text-2xl text-lg font-semibold leading-6 md:tracking-[0.24px] tracking-[0.18px] mt-6'>
                        Analysing Failed
                    </Typography.Text>
                    <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-medium leading-6 md:tracking-[0.16px] tracking-[0.14px] mt-5'>
                        Please try again
                    </Typography.Text>
                </div>
                <div className='md:mt-10 mt-[72px] text-center'>
                    <Button type="primary" className="font-poppins text-white text-base font-semibold leading-6 bg-primary md:h-12 h-11 md:w-fit w-full" onClick={() => { setStatus(''); localStorage.setItem('status', ''); }}><PlusOutlined/>Re Upload Resume</Button>
                </div>
            </div>
        </>
    )
}

export default ResumeStatusFailed;
