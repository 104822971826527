import React, { FC } from "react";
import { Typography } from "antd";

const NoHistoryCard: FC = () => {
    return (
		<>
			<div className="bg-white p-5 border border-solid border-secondry-200 rounded-2xl">
				<div className=" flex flex-row justify-between items-center">
					<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
						Interview History
					</Typography.Title>
				</div>
				<div className="bg-secondry-100 flex justify-center items-center rounded-lg xl:h-44 md:h-36 h-28 p-6 mt-4">
					<Typography.Title level={2} className='font-poppins text-secondry-400 md:text-lg text-base font-medium leading-normal xl:tracking-[1px] m-0'>
						No History
					</Typography.Title>
				</div>
			</div>
		</>
    );
};

export default NoHistoryCard;
