import React, {FC, MouseEventHandler} from 'react';

interface ChatSuggestionProps {
    message: string
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled: boolean
}

const ChatSuggestion: FC<ChatSuggestionProps> = ({
                                                     message,
                                                     onClick,
                                                     disabled = false,
                                                 }) => {
    return (
        <button
            className='m-0 leading-[21px] border border-solid !border-neutral-400 !text-neutral-400 px-2 py-0.5 rounded hover:!text-blue-400 hover:!border-blue-400 hover:!bg-blue-50 flex-none bg-white hover:!cursor-pointer'
            onClick={onClick}
            disabled={disabled}
        >
            {message}
        </button>
    );
};

export default ChatSuggestion;