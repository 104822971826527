import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  // Progress,
  Skeleton,
  Space,
  Typography,
} from "antd";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CrownOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../../styles/interview-chat.css'
import InterviewSuggestion from "../../components/common/InterviewSuggestion";
import StaticProgressBar from "../../components/common/StaticProgressBar";
import CountdownTimer from "./CountdownTimer";

interface ChatForm {
  message: string;
}

interface chatsType {
  message: string;
  message_sender: string;
}

const InterviewChat = () => {
  const [form] = Form.useForm<ChatForm>();
  const [loading, setIsLoading] = useState(true);
  const [chatLoading, setChatLoading] = useState(true);
  const [isMessageEmpty, setIsMessageEmpty] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  // const [actualScore, setActualScore] = useState(0);
	// const [totalScore, setTotalScore] = useState(0);

  const [chats, setChats] = useState<chatsType[]>([]);
  const [sessionKey, setSessionKey] = useState(
    localStorage.getItem("sessionKey")
  );

  const params = {
    session_key: sessionKey,
  };

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
  };

  const navigate = useNavigate();
  let intervalId: any;

  // const percentageScore = parseFloat(((actualScore / totalScore) * 100).toFixed(2));
  // let color = '';
  // if (percentageScore <= 25) {
  //     color = '#DA0C0C'
  // } else if (percentageScore <= 50) {
  //     color = '#E59700'
  // } else if (percentageScore <= 75) {
  //     color = '#D3E500'
  // } else if (percentageScore <= 90) {
  //     color = '#5AA000'
  // } else if (percentageScore <= 100) {
  //     color = '#72CA00'
  // } else {
  //   color = '#2D2D2D'
  // }

  // const customFormat = (percent: any) => {
  //     return <span className='font-poppins text-2xl font-bold' style={{ color: color }}>{`${percent}%`}</span>;
  // };

  const clearTimeoutID = () => {
    clearTimeout(intervalId);
  }

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/interview/question-answering/chats/`,
        {
          params: params,
          ...config,
        }
      )
      .then((response) => {
        setIsLoading(false);
        setChatLoading(false);
        setSessionKey(response.data.session_id);
        localStorage.setItem("sessionKey", response.data.session_id);
        setChats(response.data.data.slice(1));
      })
      .catch((error) => {
        console.log(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  // Below code for force that user enter key then submit the form
  // const handleKeyDown = (e: any) => {
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     e.preventDefault();
  //     onFinish({'message': e.target.value});
  //   }
  // };

  const onFinish = (values: ChatForm) => {
    setChatLoading(true);
    form.resetFields();
    setChats((prevChats) => [
      ...prevChats,
      { message: values.message, message_sender: "H" },
    ]);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/interview/question-answering/`,
        {
          message: values.message,
          session_key: sessionKey,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
        setSessionKey(response.data.session_id);
        setProgressValue(response.data.progress);
        localStorage.setItem("sessionKey", response.data.session_id);
        if (response.data.status === "completed") {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}api/interview/score-status/`,
              config
            )
            .then((response) => {
              // const data = response.data.data;
              // setActualScore(data.score_recieved);
              // setTotalScore(data.total_score);
            })
            .catch((error) => {
              console.log(error);
              // setActualScore(0);
              // setTotalScore(0);
            });
          setModalOpen(true);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          intervalId = setTimeout(() => {
            navigate("/courses");
          }, 10000);
        }

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}api/interview/question-answering/chats/`,
            {
              params: {
                session_key: sessionKey,
              },
              ...config,
            }
          )
          .then((response) => {
            setSessionKey(response.data.session_id);
            localStorage.setItem("sessionKey", response.data.session_id);
            setChatLoading(false);
            setIsLoading(false);
            setChats(response.data.data.slice(1));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!chats || chats.length === 0) return;
    const el = document.getElementById("chat-history-container");
    if (el) {
      el.scrollTo({
        top: el.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chats]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/interview/score-status/`,
        config
      )
      .then((response) => {
        const data = response.data.data;
        if (data.isInterviewTaken) {
          setModalOpen(true);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          intervalId = setTimeout(() => {
            navigate("/courses");
          }, 10000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div className="bg-secondry-100 flex flex-col xl:px-32 xl:py-10 md:px-8 px-4 pt-20 pb-10">
        <CountdownTimer minutes={30} />
        <div className="xl:h-[calc(100vh-146px)] md:h-[calc(100vh-200px)] h-[calc(100vh-180px)] flex flex-col justify-end">
          <Space
            size="large"
            direction="vertical"
            className="w-full xl:max-h-[calc(100vh-378px)] max-h-[calc(100vh-370px)] overflow-auto custom-scrollbar mb-6 pr-4 bottom-0"
            id="chat-history-container"
            ref={messagesEndRef}
          >
            {loading ? (
              <>
                <div className="chats relative flex flex-row gap-4 items-end">
                  <Avatar
                    size="default"
                    style={{
                      backgroundColor: "#090024",
                    }}
                    icon={
                      <CrownOutlined />
                    }
                  />
                  <div className="chat flex items-center md:w-[40%] w-[70%] rounded-2xl bg-white h-14 px-4 left-arrow">
                    <Skeleton.Input size="small" active block/>
                  </div>
                </div>
              </>
            ) : (
              <>
                {chats.map((message, idx) => (
                  <div
                    className={['chats relative flex flex-row gap-4 items-end', message.message_sender === "H" ? 'ml-auto flex-row-reverse' : ''].join(' ')}
                    key={`message-${idx}`}
                  >
                    <Avatar
                      size="default"
                      style={{
                        backgroundColor:
                          message.message_sender === "A"
                            ? "#090024"
                            : "#028979",
                      }}
                      icon={
                        message.message_sender === "A" ? (
                          <CrownOutlined />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                    <Typography.Text className={['chat md:max-w-[60%] max-w-[80%] w-fit rounded-2xl font-poppins text-secondry-400 text-sm font-normal leading-normal tracking-[0.14px] bg-white px-4', (idx % 2) === 0 ? 'left-arrow' : 'right-arrow'].join(' ')}>
                      <ReactMarkdown>
                        {typeof message.message === "string"
                          ? message.message
                          : ""}
                      </ReactMarkdown>
                    </Typography.Text>
                  </div>
                ))}
                {chatLoading ? (
                  <div className="chats relative flex flex-row gap-4 items-end">
                    <Avatar
                      size="default"
                      style={{
                        backgroundColor: "#090024",
                      }}
                      icon={
                        <CrownOutlined />
                      }
                    />
                    <div className="chat flex items-center md:w-[40%] w-[70%] rounded-2xl bg-white h-14 px-4 left-arrow">
                      <Skeleton.Input size="small" active block/>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </Space>
          {chatLoading || loading ? (
            <>
              <div className='w-full flex justify-start items-center mb-3 gap-2 no-scrollbar'>
                  <InterviewSuggestion
                    message="Don't know"
                    onClick={() => {}}
                    disabled={true}
                  />
              </div>
              <Form
                form={form}
                layout="inline"
                name="chat-form"
                onFinish={() => {}}
                onReset={() => {}}
              >
                <Form.Item name="message" className="!flex-1">
                  <Input.TextArea
                    placeholder="Please Give the answers"
                    style={{ height: "48px" }}
                    disabled={true}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item className="!m-0">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#c5c5c5",
                      height: "48px",
                      padding: "14px",
                    }}
                    disabled={true}
                  >
                    <SendOutlined style={{ color: "#000000", fontSize: 20 }} />
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <div className='w-full flex justify-start items-center mb-3 gap-2 no-scrollbar'>
                  <InterviewSuggestion
                    message="Don't know"
                    onClick={() => onFinish({'message': "Don't know"})}
                    disabled={false}
                  />
              </div>
              <Form
                form={form}
                layout="inline"
                name="chat-form"
                onFinish={isMessageEmpty ? () => {} : onFinish}
                onReset={onReset}
              >
                <Form.Item name="message" className="!flex-1">
                  <Input.TextArea
                    placeholder="Please Give the answers"
                    autoComplete="off"
                    rows={3}
                    onChange={(e) =>
                      setIsMessageEmpty(e.target.value.trim() === "")
                    }
                    // onPressEnter={handleKeyDown}
                    autoFocus
                  />
                </Form.Item>
                <Form.Item className="!m-0">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#c5c5c5",
                      height: "48px",
                      padding: "14px",
                    }}
                    disabled={isMessageEmpty}
                  >
                    <SendOutlined style={{ color: "#000000", fontSize: 20 }} />
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
        <div className="progress-bar-container">
<StaticProgressBar value={progressValue} />
</div>
      </div>
      <Modal
        width={400}
        centered
        open={modalOpen}
        closable={false}
        style={{ borderRadius: "16px !important" }}
        className="rounded-2xl"
        onCancel={() => {
          setModalOpen(false);
          navigate("/courses");
        }}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex items-center gap-2">
            <CheckCircleOutlined className="text-5xl text-custom-green" />
            <Typography.Text className="font-poppins text-custom-green text-2xl font-semibold leading-normal">
              Successfully Completed
            </Typography.Text>
          </div>
          <div className="flex items-center gap-3 mt-5">
            <img src="/assets/forgot-page.png" className="w-9 h-9" alt="" />
            <Typography.Text className="font-poppins text-secondry-400 text-sm font-normal leading-6">
              <span className="text-primary font-semibold">Tips:</span> Learn Our latest courses with just one click
            </Typography.Text>
          </div>
          <Button
            type="primary"
            className="font-poppins text-white text-base font-semibold leading-normal bg-primary rounded-lg flex items-center h-12 px-10 mt-4"
            onClick={() => {clearTimeoutID(); navigate('/courses');}}
          >
            Learn Courses <ArrowRightOutlined />
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InterviewChat;
