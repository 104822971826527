import React, {FC, useEffect, useState} from 'react';
import {Col, Divider, Row, Tabs, TabsProps, Typography} from "antd";
import UserDetails from "./Profile/UserDetails";
import UserScore from "./Profile/UserScore";

interface ProfilePageProps {
    checkResumeUploaded: () => void;
}

const ProfilePage: FC<ProfilePageProps> = ({checkResumeUploaded}) => {

    const [activeKey, setActiveKey] = useState('your-score');

    const onTabChange = (key: string) => {
        setActiveKey(key);
    }

    const items: TabsProps['items'] = [
        {
            label: 'User Details',
            key: 'user-details',
            children: (
                <>
                    <Typography className='text-2xl mb-4'>
                        User Details
                    </Typography>
                    <Divider/>
                    <UserDetails/>
                </>
            ),
        },
        {
            label: 'Your Score',
            key: 'your-score',
            children: (
                <>
                    <Typography className='text-2xl mb-4'>
                        Your Score
                    </Typography>
                    <Divider/>
                    <UserScore/>
                </>
            ),
        },
    ]

    useEffect(() => {
        checkResumeUploaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
            <Row justify='center' className='xl:pt-[54px] pt-[98px]'>
                <Col span={24}>
                    <Typography.Title className='text-center'>
                        Profile
                    </Typography.Title>
                </Col>
                <Col span={16}>
                    <Tabs
                        activeKey={activeKey}
                        onChange={onTabChange}
                        centered
                        tabPosition='left'
                        items={items}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ProfilePage;