import React, {FC} from 'react';
import {Card, theme} from "antd";

interface TopicCardSkeletonProps {
}

const TopicCardSkeleton: FC<TopicCardSkeletonProps> = () => {
    return (
        <Card
            loading
            hoverable
            className='topic-card relative'
            style={{
                backgroundColor: theme.getDesignToken().colorInfoBg,
                borderColor: theme.getDesignToken().colorInfoBorder,
            }}
        />
    );
};

export default TopicCardSkeleton;
