import React, { FC } from 'react';
import { Progress } from 'antd';

interface ProgressBarProps {
    value: number;
}

const StaticProgressBar: FC<ProgressBarProps> = ({ value }) => (
    <Progress percent={value} />
);

export default StaticProgressBar;
