import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; 
import { getUsDetails } from '../../store/usDetailsSlice';
import { ThunkDispatch } from 'redux-thunk';
import { Tooltip } from 'antd';

interface UserAvatarProps {
}

const UserAvatar: FC<UserAvatarProps> = () => {
  const dispatch: ThunkDispatch<RootState, unknown, any> = useDispatch();
  const { email, first_name, last_name } = useSelector((state: RootState) => state.usDetails);

  const initials = first_name && last_name ? `${first_name[0]}${last_name[0]}` : email?.[0]?.toUpperCase() || '';

  const truncatedEmail = email ? `${email.slice(0, 12)}...` : '';

  // eslint-disable-next-line
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    dispatch(getUsDetails());
  }, [dispatch]);

  return (
    <div className='text-center p-8 mt-8' style={{ borderTop: '1px solid #222222', borderBottom: '1px solid #222222' }}>
      <div className="rounded-full bg-blue-950 h-16 w-16 flex items-center justify-center mx-auto mb-2" style={{border: '1px solid #4893DB'}}>
        <span className="text-2xl" style={{color:"#4893DB"}}>{initials}</span>
      </div>
      <Tooltip title={first_name && last_name ? `${first_name} ${last_name}` : email}>
        <p className='text-white' onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
          {first_name} {last_name || truncatedEmail}
        </p>
      </Tooltip>
    </div>
  );
};

export default UserAvatar;
