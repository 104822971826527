import {createApi, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react'
import {RootState, store} from "../index";
import {logoutUser} from "../auth.slice";
import {errorNotification} from "../notification.slice";
import {ReactNode} from "react";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8000/',
	prepareHeaders: (headers, {getState}) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = (getState() as RootState).auth.authToken
		if (token) {
			headers.set('Authorization', `Bearer ${token}`)
		}
		headers.set('Accept', '*/*');
		return headers
	},
});

// Check status and return to login page if Unauthorized
const baseQueryWithUnauthorizedHandler: typeof baseQuery = async (request, api, options) => {
	const response = await baseQuery(request, api, options);
	if ('error' in response) {
		if (response.error?.status === 401 && !(window.location.pathname.includes('/auth/login'))) {
			// Redirect to the login page
			store.dispatch(logoutUser());
			window.location.href = '/auth/login';
		}
		store.dispatch(errorNotification({
			message: parseErrorMessage(response.error?.data),
		}));
	}
	return response;
};

const parseErrorMessage = (errors: any): ReactNode => {
	if (typeof errors === 'string') {
		return errors;
	}
	if (typeof errors === 'object') {
		const errorTypes = Object.values(errors);
		if (typeof errorTypes[0] === 'string') {
			return errorTypes[0];
		}
		if (Array.isArray(errorTypes[0]) && errorTypes[0].length > 0) {
			return errorTypes[0][0];
		}
	}
	return 'Something wrong. Please try again!'
}

const baseQueryWithRetry = retry(baseQueryWithUnauthorizedHandler, {maxRetries: 0})

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
	/**
	 * `reducerPath` is optional and will not be required by most users.
	 * This is useful if you have multiple API definitions,
	 * e.g. where each has a different domain, with no interaction between endpoints.
	 * Otherwise, a single API definition should be used in order to support tag invalidation,
	 * among other features
	 */
	reducerPath: 'baseApi',
	/**
	 * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
	 */
	baseQuery: baseQueryWithRetry,
	/**
	 * Tag types must be defined in the original API definition
	 * for any tags that would be provided by injected endpoints
	 */
	tagTypes: ['Course', 'Topic'],
	/**
	 * This api has endpoints injected in adjacent files,
	 * which is why no endpoints are shown below.
	 * If you want all endpoints defined in the same file, they could be included here instead
	 */
	endpoints: () => ({}),
})

export const enhancedApi = api.enhanceEndpoints({
	endpoints: () => ({
		getTeam: () => 'courses',
	}),
})
