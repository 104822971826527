import React, {FC} from 'react';
import {Card, Skeleton, theme} from "antd";

interface CourseCardSkeletonProps {
}

const CourseCardSkeleton: FC<CourseCardSkeletonProps> = () => {
    return (
        <Card
            loading
            hoverable
            bordered
            bodyStyle={{backgroundColor: theme.getDesignToken().colorPrimaryBg}}
            cover={
                <Skeleton.Image
                    className='!flex !w-auto justify-center align-middle p-5 rounded-t-lg'
                    style={{backgroundColor: theme.getDesignToken().colorPrimaryBgHover}}
                />
            }
        >
            <Card.Meta title='title' description='lorem ipsum text here..'/>
        </Card>
    );
};

export default CourseCardSkeleton;
