import React, { FC, useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';
import ResumeUpload from './ResumeUpload';
import ResumeStatusInprogress from './ResumeStatusInprogress';
import ResumeStatusFailed from './ResumeStatusFailed';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

interface ResumePageProps {
    isUserValidForInterview: boolean;
    setIsResumeAlreadyUploaded: (newState: string) => void;
}

const ResumePage: FC<ResumePageProps> = ({isUserValidForInterview, setIsResumeAlreadyUploaded}) => {
    const [status, setStatus] = useState(localStorage.getItem('status'));
    const [jobProfile, setJobProfile] = useState('Select job profile...');
    const [uploadedResume, setUploadedResume] = useState(localStorage.getItem('resumeUploaded'))
    const navigate = useNavigate();

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    };

    useEffect(() => {
        if (uploadedResume === 'true' && localStorage.getItem('status') !== '') {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}api/interview/status/`, config)
                .then((response) => {
                    const data = response.data.data;
                    setJobProfile(data.field);
                    setStatus(data.status);
                    localStorage.setItem('status', data.status);
                    if (data.status === 'DONE') {
                        setIsResumeAlreadyUploaded('true');
                        navigate('/interview-detail');
                    }
                })
                .catch((error) => {
                    setStatus('');
                    localStorage.setItem('status', '');
                    console.log(error);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='bg-white xl:h-full md:h-[calc(100vh-192px)] h-[calc(100vh-188px)] xl:px-12 md:px-9 px-6 md:py-6 py-4'>
            <Typography.Title className='font-poppins text-secondry-400 md:text-[22px] text-base font-medium leading-normal xl:tracking-[1px] m-0'>
                Upload your Resume
            </Typography.Title>
            <Typography.Text className='font-poppins text-secondry-300 md:text-base text-sm font-normal leading-normal xl:tracking-[1px]'>
                Our AI will analyse your resume and you can attend interviews according to your profile.
            </Typography.Text>
            <div className='xl:w-[788px] md:w-[700px] w-full mt-2'>
                <Divider className='h-1 bg-primary rounded-[30px] m-0'/>
            </div>
            {
                status === 'Pending' || status === 'processing' ? <ResumeStatusInprogress setStatus={setStatus} setIsResumeAlreadyUploaded={setIsResumeAlreadyUploaded}/> : status === 'Failed' ? <ResumeStatusFailed setStatus={setStatus}/> : <ResumeUpload currentJobProfile={jobProfile} setStatus={setStatus} setUploadedResume={setUploadedResume} isUserValidForInterview={isUserValidForInterview}/>
            }
        </div>
    )
}

export default ResumePage;
