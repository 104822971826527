import React, {FC} from 'react';
import {Badge, BadgeProps, Card, theme, Typography} from "antd";
import {TopicInterface} from "../store/types";
import {useNavigate} from "react-router-dom";
import AIButton from "./common/AIButton";

interface TopicCardProps {
  topic: TopicInterface
  idx: number
}

const TopicCard: FC<TopicCardProps> = ({topic, idx}) => {

  const navigate = useNavigate();
  const {useToken} = theme;
  const {token} = useToken();

  const goToTopic = () => {
    navigate(`topic/${topic.topicId}`);
  }

  const badgeColorMapping: Record<TopicInterface['status'], BadgeProps['color']> = {
    "Not Started": token.colorInfoActive,
    Started: token.colorWarningText,
    Done: token.colorSuccess,
  }

  return (
    <Badge.Ribbon text={topic.status} color={badgeColorMapping[topic.status]}>
      <Card
        hoverable
        bordered
        className='topic-card relative'
        style={{
          backgroundColor: token.colorInfoBg,
          borderColor: token.colorInfoBorder,
        }}
      >
        <Typography.Text
          className='topic--idx absolute -top-4 -left-2 text-2xl font-bold from-indigo-700 to-indigo-400 bg-gradient-to-r bg-clip-text text-transparent'
        >
          {(idx + 1).toString().padStart(2, '0')}
        </Typography.Text>
        <div>
          <Typography.Text className='text-2xl block mb-5 capitalize'>
            {topic.topicName}
          </Typography.Text>
        </div>
        <AIButton
          variant='primary'
          className='mt-3'
          onClick={goToTopic}
          disabled={topic.status === 'Done'}
        >
          {topic.status === 'Not Started' ? 'Start' : 'Continue'}
        </AIButton>
      </Card>
    </Badge.Ribbon>
  );
};

export default TopicCard;
