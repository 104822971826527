import {api} from "./index";
import {CourseInterface, SnakeCaseKeys, TopicInterface} from "../types";
import {snakeToCamelCase} from "../../utils/caseConvertor";

interface GetTopicsPayload {
    courseId: number
}

interface GetKidsTopicsPayload {
    kidsId: string
}

export const courseApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCourses: build.query<CourseInterface[], void>({
            query: () => ({url: 'api/user-courses'}),
            transformResponse: (
                response: {
                    data: SnakeCaseKeys<CourseInterface>[]
                }
            ) => snakeToCamelCase<SnakeCaseKeys<CourseInterface>>(response.data),
        }),
        getTopics: build.query<TopicInterface[], GetTopicsPayload>({
            query: ({courseId}) => ({
                url: `api/topic-courses/${courseId}`,
            }),
            transformResponse: (
                response: {
                    data: SnakeCaseKeys<TopicInterface>[]
                }
            ) => snakeToCamelCase<SnakeCaseKeys<TopicInterface>>(response.data),
        }),
        getKidsCourses: build.query<CourseInterface[], GetKidsTopicsPayload>({
            query: ({kidsId}) => ({
                url: 'api/kids/courses/',
                params: { kids_id: kidsId },
            }),
            transformResponse: (
                response: {
                    data: SnakeCaseKeys<CourseInterface>[]
                }
            ) => snakeToCamelCase<SnakeCaseKeys<CourseInterface>>(response.data),
        }),
        getKidsTopics: build.query<TopicInterface[], GetKidsTopicsPayload>({
            query: ({kidsId}) => ({
                url: `api/kids/1/`,
                params: { kids_id: kidsId },
            }),
            transformResponse: (
                response: {
                    data: SnakeCaseKeys<TopicInterface>[]
                }
            ) => snakeToCamelCase<SnakeCaseKeys<TopicInterface>>(response.data),
        })
    }),
})

export const {
    useGetCoursesQuery,
    useGetTopicsQuery,
    useGetKidsCoursesQuery,
    useGetKidsTopicsQuery,
} = courseApi;
